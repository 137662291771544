import React from "react";
import {
  StJohnMainContainer1,
  PageHeadingParallax,
  ParagraphA,
  GeneralTextAndImageOnSidePanel1,
} from "../../layout/containers/containers.jsx";
import { StjMainContainerFontSourceSansProH4 } from "../../layout/headings/headings.jsx";

export const MinistriesPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/icons_and_iconostasis_1.jpg"}
      text={"Ministry Groups at St John's"}
    />
    <StJohnMainContainer1>
      <GeneralTextAndImageOnSidePanel1
        imgSrc={
          "./assets_2/albums/2022/Mothers%20Day%20Luncheon%20(05@8@22)/IMG_4878.jpg"
        }
      >
        <StjMainContainerFontSourceSansProH4>
          Brotherhood of St. James
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Meeting time and place: The Brothers will start meeting in the fall of
          2022 on a day and time tbd. The Brotherhood of St. James is the
          parish's fellowship for men and will benefit both the parish and
          community with a focus on promoting fraternal Christian fellowship and
          philanthropic outreach. The Brothers sponsor several social/fellowship
          and outreach events that take place throughout the year. These
          include:
          <ol className="ps-5">
            <li>Palm Sunday Fish Dinner, co-sponsored with the Sisterhood.</li>
            <li>Mother's Day Mimosa Brunch.</li>
            <li>Late July's Parish-wide Family Picnic.</li>
            <li>
              Parish Christmas Party, co-sponsored with JOY and the Sisterhood.
            </li>
            <li>
              Other events, such as Father-Son fishing trips and Father-Daughter
              Dances.
            </li>
          </ol>
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      <GeneralTextAndImageOnSidePanel1
        imgSrc={
          "./assets_2/albums/2022/Sisterhood%20of%20St%20Xenia%20Welcome%20Back%20Ladies%20Tea%20(10@9@22)/IMG_20221009_131805.jpg"
        }
        imageSideOnLeft={false}
      >
        <StjMainContainerFontSourceSansProH4>
          Sisterhood of St. Xenia
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Meeting time and place: The Sisters meet in the parish hall during
          Coffee Hour on the second Sunday of the month from October through
          June.
        </ParagraphA>
        <ParagraphA>
          The Sisterhood of St. Xenia of St. Petersburg is the parish's
          fellowship for women and the parish's main social and spiritual arm
          for parish women. The Sisters plan social, outreach and spiritually
          enriching events that are of spiritual and material benefit to St.
          John's and the people we try to reach and serve.
        </ParagraphA>
        <ParagraphA>
          The Sisterhood sponsors a majority of the major social/fellowship and
          outreach events that take place throughout the year. These include:
          <ol className="ps-5">
            <li>
              Super Bowl party, 1st Sunday of February (in honor of the parish
              men)
            </li>
            <li>Forgiveness Sunday's Blini Luncheon</li>
            <li>
              Mid-Lent's (Third Sunday) Fasoulata Luncheon (a Greek/Balkan
              tradition)
            </li>
            <li>
              Palm Sunday Fish Dinner (joint venture with the Brotherhood of St.
              James)
            </li>
            <li>Great & Holy Thursday's tomb decorating and egg dying</li>
            <li>Strawberry Festival, early June</li>
            <li>
              September 25, reception following Vespers of St. John the
              Theologian
            </li>
            <li>Monthly (as scheduled) Ladies' Teas</li>
            <li>Plus numerous other socials and fundraisers during the year</li>
          </ol>
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      <GeneralTextAndImageOnSidePanel1
        imgSrc={
          "./assets_2/albums/2022/Archbishop%20Michael%20Visit%20(12@11@22)/IMG_7975.jpg"
        }
      >
        <StjMainContainerFontSourceSansProH4>
          Choir and Readers
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Rehearsal time and place: Rehearsals can either be held right after
          Liturgy on a Sunday or on a Wednesday night beginning around 7:00 pm.
          Consult the calendar and/or Choir Director (Rob Sirico) for specific
          rehearsal dates and times.
        </ParagraphA>
        <ParagraphA>
          Divine services in the Orthodox Church are sung a capella, without any
          instruments. Singers and Readers compliment the beauty of the services
          with their singing of the divine hymns and chanting of the holy
          scriptures
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      {/* <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Church School "JOY" and JOYful Parents
        </StjMainContainerFontSourceSansProH4>
        <p className="fst-italic">
          "With us everything should be secondary compared to our concern with
          children, and their upbringing in the instruction and teaching of the
          Lord." - St. John Chrysostom
        </p>

        <ParagraphA>
          Meeting time and place: JOY takes place in the parish hall,
          downstairs, following the conclusion of Holy Communion, when the
          children are dismissed to attend class sessions. Classes hold to a
          schedule similar to the public schools, from September to the end of
          May with special summer projects in August.
        </ParagraphA>
        <ParagraphA>
          St. John's church school program is known as JOY ("Jesus, Others,
          You") and is generally divided by age and grade using material from
          many excellent sources. A parents auxiliary known as JOYful Parents
          provides support for the wonderful and fun social events we plan for
          the children throughout the year
        </ParagraphA>
        <ParagraphA>
          In addition to the Sunday educational program, JOY sponsors several
          events throughout the year for the social upbringing and edification
          of parish youth:
          <ol className="ps-5">
            <li>
              JOY Prosfora Club: Parish children gather on a Saturday and make
              the prosfora bread that is offered on Sundays for the Divine
              Liturgies of that month. Start time is usually around 3 pm and
              concludes that day with Vespers.
            </li>
            <li>
              St. Valentine's Day Belgian Waffle Brunch: Our JOY kids help
              setup, cook, serve and clean-up all in honor of their parents and
              parish friends.
            </li>
            <li>
              Great & Holy Friday Tomb Vigil: Tweens and Teens ages 11 and up,
              with parents' permission, stay at the church from the Matins of
              Lamentations throughout the night taking turns reading from the
              Holy Scriptures at the holy Tomb of our Lord.
            </li>
            <li>
              Summer Retreat & Sleepover: Held in August during either the Great
              Feast of the Transfiguration or Dormtion, this two-day event
              features lots of activities including a day beach trip and evening
              campfire.
            </li>
            <li>
              Other events such as talent shows, ice cream socials, and trips
              away from the parish.
            </li>
          </ol>
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1> */}

      {/* <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Feed the Need
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Parishioners collect and dontate to the parish food pantry canned
          goods, dry good, toiletries and other useful and needed items (like
          toys during Christmas) for distribution to either those who call the
          church directly or to those churches in the neighboring community who
          might be better able to distribute them. Special emphasis is given to
          these collections during November, December and during the Great Fast
          (i.e., Great Lent). Parishioners help and take turns in distributing
          the food baskets during these times.
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1> */}

      <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Greeters
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          At the frontline of a parish visit, Greeters play an important role in
          welcoming newcomers to the church for services and making sure their
          time with us is spiritually and socially fulfilling. This includes
          inviting the guest to add their address and email to our parish
          mailing list, providing them with the bulletin and, if needed, prayer
          book, and facilitating their participation in Coffee Hour where they
          can meet parishioners and make important connections with new friends
          and neighbors.
        </ParagraphA>
        <ParagraphA>
          Meeting time and place: Greeters, after completing training, do not
          meet per se. Occasional meetings may be held prior to important or
          large events or services. Consult the calendar for further
          information.
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      {/* <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Outreach
        </StjMainContainerFontSourceSansProH4>
        <p className="fst-italic">
          Meeting time and place: Meetings are held occasionally. Consult the
          calendar for scheduled meetings.
        </p>
        <ParagraphA>
          The Outreach Ministry plans events that will spread the Gospel of
          Jesus Christ and make our parish's role in the spreading of that
          gospel known to the wider community. Ministry work centers around
          promoting both knowledge of the Orthodox Faith as well as of our
          parish community from both within the parish as well as from without,
          in the wider community. This includes the web and Facebook sites,
          advertising, outreach at parish, civic and community events, guest
          speakers, seekers and inquirers classes, seminars and workshops (such
          as our yearly icon workshop and Christmas concerts) promoting church
          growth, leadership and the Christian use of our God-given gifts and
          talents. Also, we plan community-based events that bring our parish
          into the wider community.
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1> */}

      <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Parish Council
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Meeting time and place: The Parish Council meets monthly usually on
          the first Wednesday after the first Sunday of the month, beginning at
          7:00 pm. The Council consists of both elected and appointed members
          who provide overall parish leadership and direction, and also acts as
          the parish’s primary Strategic Planning Committee to discuss, plan and
          evaluate the parish's overall health and short- and long-term goals
          and objectives.
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      <GeneralTextAndImageOnSidePanel1
        imgSrc={
          "./assets_2/albums/2022/St%20John%20the%20Theologian%20Icon%20Workshop%20(10@10@22)/IMG_8583.jpg"
        }
      >
        <StjMainContainerFontSourceSansProH4>
          Icon Studio
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          We are very blessed at St. John the Theologian Church to have a room
          dedicated to the study and practice of iconography. This ministry is
          headed by our own iconographer Lucia Adele McHugh. For details of the
          schedule of the icon studio and lessons, speak to Lucia. The regular
          schedule of the studio is Wednesdays from 3:30 - 9:00 PM but other
          hours are available also.
        </ParagraphA>
        <ParagraphA>
          Many people have written about the meaning of icons, their sacred
          purpose in the Church, the process of Iconography, and the study of
          iconology. We read that icons are “windows to heaven” and that each
          person is an Icon created by God, made in the image and likeness of
          God. But the best way to understand Icons is to learn to write one
          yourself. We are all called to experience the journey of iconography
          and one does not need to have artistic talent to do so. The desire to
          write an Icon is all that is needed.
        </ParagraphA>
        <ParagraphA>
          <div className="fst-italic">
            “Desire and effort must come from you. God will provide the power
            and the result.”
            <br />
            -St. Paisios
          </div>
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1>

      {/* <GeneralTextAndImageOnSidePanel1>
        <StjMainContainerFontSourceSansProH4>
          Teens
        </StjMainContainerFontSourceSansProH4>
        <ParagraphA>
          Meeting time and place: Check the monthly schedule for times and
          locations. The Teen Group is personally led by Fr. Jonathan.
        </ParagraphA>
        <ParagraphA>
          The parish teen program begins with the seventh grade and a child's
          12th birthday. Teen ministry is geared to meet the spiritual and
          social needs of our adolescent members. Teens have a number of yearly
          events that they look forward to, including the overnight vigil on
          Holy Friday and an annual weekend retreat in the Poconoes in October.
          In past years their fundraising activities has brought in over $1,000
          to the Make-A-Wish Foundation.
        </ParagraphA>
      </GeneralTextAndImageOnSidePanel1> */}
    </StJohnMainContainer1>
  </div>
);
