import React from "react";
import {
  PageHeadingParallax,
  StJohnMainContainer1,
  ParagraphA,
} from "../../layout/containers/containers.jsx";
import { StjMainContainerFontPatuaH2 } from "../../layout/headings/headings.jsx";

export const OurPatronSaintPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/stjohn_large_icon.jpeg"}
      text={"Our Patron Saint - Saint John the Theologian"}
    />
    <StJohnMainContainer1>
      <ParagraphA>
        (known in the Western Church as St. John the Evangelist or St. John the
        Divine).
      </ParagraphA>
      <br />
      <StjMainContainerFontPatuaH2>Introduction</StjMainContainerFontPatuaH2>
      <ParagraphA>
        The Holy Apostle and Evangelist John the Theologian was the beloved
        disciple of Christ the Savior. He, Peter and James, were witnesses of
        the Lord's greatest miracles. In iconography, St. John is depicted as a
        gentle, and spiritual Elder, with gentle and calm features, and the look
        of one who contemplates unutterable mysteries.
      </ParagraphA>
      <ParagraphA>
        St. John is called "the Apostle of Love." His writings are permeated by
        love, which leads to the understanding that God is Love (1 Jn 4:8). In
        his writings, St. John focuses on God's inexpressible love for the world
        and for mankind. He constantly exhorts his disciples to love one
        another.
      </ParagraphA>
      <ParagraphA>
        He was also the steadfast opponent of heresy (2 Jn 10-11). When he
        encountered Cerinthus in the baths of Ephesus, the heretic asked John if
        he recognized him. "I do recognize you," the saint replied, "I recognize
        the offspring of Satan." (Eusebius, Hist. Eccles. Book 3: 28, and St.
        Irenaeus, Against Heresies Book 3, Ch. 3).
      </ParagraphA>
      <br />
      <StjMainContainerFontPatuaH2>His Life</StjMainContainerFontPatuaH2>
      <ParagraphA>
        The Holy, Glorious Apostle and Evangelist, Virgin, and Beloved Friend of
        Christ, John the Theologian was the son of Zebedee and Salome, a
        daughter of St. Joseph the Betrothed. He was called by our Lord Jesus
        Christ to be one of His Apostles at the same time as his elder brother
        James. This took place at Lake Gennesareth (i.e. the Sea of Galilee).
        Leaving behind their father, both brothers followed the Lord.
      </ParagraphA>
      <ParagraphA>
        The Apostle John was especially loved by the Savior for his sacrificial
        love and his virginal purity. After his calling, the Apostle John did
        not part from the Lord, and he was one of the three apostles who were
        particularly close to Him. St. John the Theologian was present when the
        Lord restored the daughter of Jairus to life, and he was a witness to
        the Transfiguration of the Lord on Mount Tabor.
      </ParagraphA>
      <ParagraphA>
        During the time of the Last Supper, he reclined next to the Lord, and
        laid his head upon His breast. He also asked the name of the Savior's
        betrayer. The Apostle John followed after the Lord when they led Him
        bound from the Garden of Gethsemane to the court of the iniquitous High
        Priests Annas and Caiphas. He was there in the courtyard of the High
        Priest during the interrogations of his Teacher and he resolutely
        followed after him on the way to Golgotha, grieving with all his heart.
      </ParagraphA>
      <ParagraphA>
        At the foot of the Cross he stood with the Mother of God and heard the
        words of the Crucified Lord addressed to her from the Cross: "Woman,
        behold your son." Then the Lord said to him, "Behold your Mother" (Jn
        19:26-27). From that moment the Apostle John, like a loving son,
        concerned himself over the Most Holy Virgin Mary, and he served her
        until her Dormition (or, “falling asleep”).
      </ParagraphA>
      <ParagraphA>
        After the Dormition of the Mother of God the Apostle John went to
        Ephesus and other cities of Asia Minor to preach the Gospel, taking with
        him his own disciple Prochoros. They boarded a ship, which floundered
        during the time of a terrible tempest. All the travelers were cast up
        upon dry ground, and only the Apostle John remained in the depths of the
        sea. Prochoros wept bitterly, bereft of his spiritual father and guide,
        and he went on towards Ephesus alone.
      </ParagraphA>
      <ParagraphA>
        On the fourteenth day of his journey he stood at the shore of the sea
        and saw that the waves had cast a man ashore. Going up to him, he
        recognized the Apostle John, whom the Lord had preserved alive for
        fourteen days in the sea. Teacher and disciple went to Ephesus, where
        the Apostle John preached incessantly to the pagans about Christ. His
        preaching was accompanied by such numerous and great miracles, that the
        number of believers increased with each day.
      </ParagraphA>
      <ParagraphA>
        During this time there had begun a persecution of Christians under the
        emperor Nero (56-68). They took the Apostle John for trial at Rome. St.
        John was sentenced to death for his confession of faith in the Lord
        Jesus Christ, but the Lord preserved His chosen one. The apostle drank a
        cup of deadly poison, but he remained alive. Later, he emerged unharmed
        from a cauldron of boiling oil into which he had been thrown on orders
        from the torturer.
      </ParagraphA>
      <ParagraphA>
        After this, they sent the Apostle John off to imprisonment to the island
        of Patmos, where he spent many years. Proceeding along on his way to the
        place of exile, St. John worked many miracles. On the island of Patmos,
        his preaching and miracles attracted to him all the inhabitants of the
        island, and he enlightened them with the light of the Gospel. He cast
        out many devils from the pagan temples, and he healed a great multitude
        of the sick.
      </ParagraphA>
      <ParagraphA>
        Sorcerers with demonic powers showed great hostility to the preaching of
        the holy apostle. He especially frightened the chief sorcerer of them
        all, named Kinops, who boasted that they would destroy the apostle. But
        the great John, by the grace of God acting through him, destroyed all
        the demonic artifices to which Kinops resorted, and the haughty sorcerer
        perished in the depths of the sea.
      </ParagraphA>
      <ParagraphA>
        The Apostle John withdrew with his disciple Prochoros to a desolate
        height, where he imposed upon himself a three-day fast. During the time
        of the Apostle John's prayer the earth quaked and thunder rumbled.
        Prochoros fell to the ground in fright. The Apostle John lifted him up
        and told him to write down what he was about to say. "I am the Alpha and
        the Omega, the beginning and the end, says the Lord, Who was and Who is
        and Who is to come, the Almighty" (Rev 1:8), proclaimed the Spirit of
        God through the Apostle John. Thus in about the year 67 the Book of
        Revelation was written, known also as the "Apocalypse," of the holy
        Apostle John the Theologian. In this Book were predictions of the
        tribulations of the Church and of the end of the world.
      </ParagraphA>
      <ParagraphA>
        After his prolonged exile, the Apostle John received his freedom and
        returned to Ephesus, where he continued with his activity, instructing
        Christians to guard against false teachers and their erroneous
        teachings. In the year 95, the Apostle John wrote his Gospel at Ephesus.
        He called for all Christians to love the Lord and one another, and by
        this to fulfill the commands of Christ. The Church calls St. John the
        "Apostle of Love", since he constantly taught that without love man
        cannot come near to God.
      </ParagraphA>
      <ParagraphA>
        In his three Epistles, St. John speaks of the significance of love for
        God and for neighbor. Already in his old age, he learned of a youth who
        had strayed from the true path to follow the leader of a band of
        robbers, so St. John went out into the wilderness to seek him. Seeing
        the holy Elder, the guilty one tried to hide himself, but the Apostle
        John ran after him and besought him to stop. He promised to take the
        sins of the youth upon himself, if only he would repent and not bring
        ruin upon his soul. Shaken by the intense love of the holy Elder, the
        youth actually did repent and turn his life around.
      </ParagraphA>
      <ParagraphA>
        St. John died when he was more than a hundred years old. He far outlived
        the other eyewitnesses of the Lord, and for a long time he remained the
        only remaining eyewitness of the earthly life of the Savior.
      </ParagraphA>
      <ParagraphA>
        When St. John was more than one hundred years old, he took seven of his
        disciples outside the city of Ephesus, and had each of them carry a
        shovel. When they reached a certain spot, St. John told them to sit down
        while he went off and prayed by himself.
      </ParagraphA>
      <ParagraphA>
        After he had finished his prayers, he came back and told them to dig his
        grave in the form of a cross, as long as he was tall. He laid himself
        down in the grave and spoke to them. He told his disciple Prochoros that
        he must go to Jerusalem, where he would end his life.
      </ParagraphA>
      <ParagraphA>
        Then St. John told them to cover him with earth. They embraced him and
        covered him up to his knees. He embraced them once more, and told them
        to take some more earth and cover him up to his neck. This they did.
        Then he told them to place a thin veil over his face, and to embrace him
        one last time, for they would not see him again in this life. The
        Apostle surrendered his soul to God, and his disciples buried him and
        returned to the city.
      </ParagraphA>
      <ParagraphA>
        When the brethren asked them where St. John was, they told them what had
        taken place. The brethren asked the seven disciples to take them to the
        grave. When the grave was opened, St. John's body was not there. Only
        his shoes remained.
      </ParagraphA>
      <ParagraphA>
        Each year from the grave of the holy Apostle John on May 8 came forth a
        fine dust, which believers gathered up and were healed of sicknesses by
        it. Therefore, the Church also celebrates the memory of the holy Apostle
        John the Theologian on May 8.
      </ParagraphA>
      <ParagraphA>
        The Lord bestowed on His beloved disciple John and John's brother James
        the name "Sons of Thunder" as an awesome messenger in its cleansing
        power of the heavenly fire. And precisely by this the Savior pointed out
        the flaming, fiery, sacrificial character of Christian love, the
        preacher of which was the Apostle John the Theologian. The eagle, symbol
        of the lofty heights of his theological thought, is the iconographic
        symbol of the Evangelist John the Theologian. The appellation
        "Theologian" is bestowed by the Holy Church only to St. John among the
        immediate disciples and Apostles of Christ, as being the seer of the
        mysterious Judgments of God.
      </ParagraphA>
    </StJohnMainContainer1>
  </div>
);
