import React from "react";
import {
  PageHeadingParallax,
  ParagraphA,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import { StjMainContainerFontPatuaH2 } from "../../layout/headings/headings.jsx";
import { StjYTVideoContainer } from "../../ui/yt_video_container/yt_video_container.jsx";

export const WhatToExpectPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/icons_and_iconostasis_1.jpg"}
      text={"What to Expect"}
    />
    <StJohnMainContainer1>
      <StjYTVideoContainer
        src={"https://www.youtube.com/watch?v=XDUMf7oFt2s"}
        fullScreen={true}
      />
      <StjMainContainerFontPatuaH2>
        What is an Orthodox Church Like?
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        There are a number of things about Orthodox Christian worship that are
        different from the services of other churches, whether Roman Catholic,
        liturgical Protestant, or Evangelical. Orthodox worship can often be
        considered "exotic" and "other-worldly", and most definitely springs
        from different origins and traditions than does Western Christian
        worship.
      </ParagraphA>
      <ParagraphA>
        We're "catholic" - but not Roman. "Orthodox" - but not Jewish.
        "Evangelical" and charismatic - but not Protestant or Evangelical. We're
        not a denomination - we're pre-denominational. We're the original church
        founded by the Apostles through the descent of the Holy Spirit on the
        Day of Pentecost. If you've ever wondered, "Whatever happened to the
        Church the Apostles left behind?" you're not alone. Orthodoxy is the
        original Church - an ancient Faith for a modern world drowning in doubt
        and confusion and searching for meaning, relevance, and true, heavenly
        worship.
      </ParagraphA>
      <ParagraphA>
        In an effort to help you better understand what a visit to an Orthodox
        church would be like, and to help you anticipate the various customs and
        practices that are both common to various Orthodox churches as well as
        particular to the way worship unfolds at St. John's, here are a few
        things that you should know prior to planning your first visit to an
        Orthodox Christian church.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        A Sense of Holiness
      </StjMainContainerFontPatuaH2>

      <ParagraphA>
        If you are from a Protestant or non-liturgical tradition, you may feel
        overwhelmed the minute you walk in the door of an Orthodox church.
        Orthodoxy has sometimes been described as an "audio-visual extravaganza"
        - a total-sensory experience that involves the whole person: mind, body
        and soul. You will find yourself surrounded by a blaze of color in the
        priest's vestments, altar coverings, and the icons that adorn the walls.
        The sweet and aromatic fragrance of incense will envelope you. Rich,
        deeply moving music will fill your ears. All around you people will be
        doing things—lighting candles, kissing icons, making the sign of the
        cross, bowing, standing in prayer—everything but sitting still. To
        someone accustomed to four bare walls and a pulpit, all this may seem
        very strange.
      </ParagraphA>
      <ParagraphA>
        It's important to remember that none of this is an end in itself.
        Everything we see, hear, smell, touch, taste, or do in the Orthodox
        Church has one purpose and one purpose only: TO LEAD US CLOSER TO GOD.
        Since God created us with physical bodies and senses, we believe He
        desires us to use our bodies and senses to grow closer to Him.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>The Music</StjMainContainerFontPatuaH2>

      <ParagraphA>
        Traditionally, Orthodox use no musical instruments in any of their
        divine services; everything is sung A Capella. St. John's is
        particularly blessed to have an extensively trained and experienced
        choir director lead the faithful in the singing of the responses and
        hymns. The style of music varies as well, from western-sounding four
        part harmony in a Slavic church to the exotic-sounding chants of the
        Byzantine tradition, with lots of variations in between.
      </ParagraphA>
      <ParagraphA>
        This constant singing may be a bit overwhelming at first; it feels like
        getting on the first step of an escalator and being carried along in a
        rush until you step off ninety minutes later. It has been fairly said
        that he Liturgy is one continuous song.
      </ParagraphA>
      <ParagraphA>
        Before long you know it by heart. Then you fall into the presence of God
        in a way you never can when flipping from prayer book to bulletin to
        hymnal.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        Standing, Sitting, Kneeling
      </StjMainContainerFontPatuaH2>

      <ParagraphA>
        Some Orthodox churches may have chairs, some may have pews, but since
        ancient, apostolic times the attitude or position of the body meant as
        much as the attitude and position of the soul; therefore, Christians
        have always stood during divine worship. Too much sitting encourages the
        person attending a worship service to become too much of a passive
        observer of worship rather than an active participant in worship.
      </ParagraphA>
      <ParagraphA>
        While Orthodox Christians may sit when they need to, they stand for most
        of the service out of respect and love for our Lord, especially during
        times when He and/or the Holy Spirit is speaking directly to us: in the
        Gospel reading, the recitation of the Nicene Creed, the Eucharistic
        Prayer, the Our Father, and the reception of Holy Communion, for
        example. If you find the amount of standing too challenging, you're
        welcome to take a seat. It get easier with practice.
      </ParagraphA>
      <ParagraphA>
        Generally, we don’t kneel on Sundays, but sometimes we do
        "prostrations." This is not like prostrations in the Roman Catholic
        tradition, lying out flat on the floor. To make a prostration we kneel,
        place our hands on the floor, and touch out foreheads between our hands.
        At first, prostration feels embarrassing, but no one else is
        embarrassed, so after a while it feels natural. In Orthodoxy there is an
        acceptance of individualized expressions of piety, rather than a sense
        that people are watching you and getting offended if you do it wrong.
      </ParagraphA>
      <ParagraphA>
        One former Anglican priest said that seeing people prostrate themselves
        was one of the things that made him most eager to become Orthodox. He
        thought, "That’s how we should be before God."
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>By This Sign</StjMainContainerFontPatuaH2>
      <ParagraphA>
        We sign ourselves whenever the Trinity is invoked, whenever we venerate
        the cross or an icon, and on many other occasions in the course of the
        Liturgy. But people aren't expected to do everything the same way, and
        there are variations in the way in which and number of times the
        faithful cross themselves, according to the custom and tradition they
        were raised with.
      </ParagraphA>
      <ParagraphA>
        We cross with our right hands, touching forehead, chest, right shoulder,
        then left shoulder to end over the heart, the opposite of Roman
        Catholics and Anglicans. We hold our hands in a prescribed way: thumb
        and first two fingertips pressed together, the last two fingers pressed
        down to the palm. Here as elsewhere, the Orthodox impulse is to make
        everything we do reinforce the Faith.—The three fingers held together
        represent the Trinity; the two fingers against the palm represent the
        two natures of Christ.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>The Kiss</StjMainContainerFontPatuaH2>
      <ParagraphA>
        We kiss things. When we first come into the church, we kiss the icons.
        During Holy Communion the faithful kiss the chalice after receiving the
        Holy Gifts. The acolytes kiss the priest's or bishop’s hand when they
        give things to him or take things from him (such as the censer), and we
        all line up to kiss the cross at the end of the service.
      </ParagraphA>
      <ParagraphA>
        Both Ss. Peter and Paul admonish us to kiss one another (e.g., "Greet
        one another with a kiss of love," 1 Peter 5:14) as a sign of the love we
        share in our Lord Jesus Christ. When Roman Catholics and Anglicans pass
        the peace, they give a hug, handshake, or peck on the cheek. In
        Orthodoxy different cultures are at play: in our tradition at St.
        John's, heavily influenced by our roots in Russian Orthodoxy, we kiss
        each other three times, one each in the Name of the Father, Son, and
        Holy Spirit. During this time, we greet one another with "Christ is in
        our midst," with the response, "He is and always shall be." The greeting
        is not the previously familiar "The peace of the Lord be with you," nor
        is it "Hi, nice church you have here."
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        Blessed Bread and Consecrated
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        Only Orthodox may take communion, but anyone may have some of the
        blessed bread. Here’s how it works: the round communion loaf, baked by a
        parishioner, is imprinted with a seal. In the preparation service before
        the Liturgy, the priest cuts out a section of the seal and sets it
        aside; it is called the "Lamb." The rest of the bread is cut up and
        placed in a large basket, and blessed by the priest.
      </ParagraphA>
      <ParagraphA>
        During the eucharistic prayer, the Lamb is consecrated to be the Body of
        Christ, and the chalice of wine is consecrated as His Blood. The priest
        then places the Lamb in the chalice. When we receive communion, we file
        up to the priest, standing and opening our mouths wide wile he gives us
        a portion of the wine-soaked bread from a spoon. He also prays over us,
        calling us by our first name or by the saint’s name which we chose when
        we were baptized or chrismated (received into the Church).
      </ParagraphA>
      <ParagraphA>
        At the end of the service, as we file past the priest and venerate the
        Cross he is holding, we come to a basket of blessed bread. People will
        take portions for themselves and for visitors and non-Orthodox friends
        around them. If someone hands you a piece of blessed bread, do not
        panic; it is not the eucharistic Body. It is a sign of fellowship.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        Confession is never "General"
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        In our experience, we don’t have any general sins; they’re all quite
        specific. There is no complete confession prayer in the Liturgy.
        Orthodox are expected to be making regular, private confession to Christ
        in the presence of their priests (i.e., the "presbyters" or "elders" St.
        Paul talks extensively about).
      </ParagraphA>
      <ParagraphA>
        The roll of the priest/pastor is much more that of a spiritual father
        than it is in other denominations. He is not called by his first name
        alone, but referred to as “Father First-Name.” His wife also holds a
        special role as parish mother, and she gets a title too, though it
        varies from one culture to another. Some of the titles used are:
        "Matushka" (Slavic), which means "Mama"; or "Khouria" (Arabic) or
        "Presbytera" (Greek), both of which mean "priest’s wife."
      </ParagraphA>
      <ParagraphA>
        Another difference you will probably notice is in the Nicene Creed. In
        the Creed we affirm that the Holy Spirit proceeds from the Father, but
        we don’t add "and the Son," as Western denominations do (such addition
        was one of the greatest theological reasons the western Roman Catholics
        broke from their eastern Orthodox Catholic brethren, a split that has
        lasted over 1,000 years). In this we adhere to the Creed as it was
        originally written in Nicea at the First Ecumenical Council of 325 A.D.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>No Shorcuts</StjMainContainerFontPatuaH2>
      <ParagraphA>
        During the first 300 years of Christian worship, liturgical worship
        lasted well over four hours. St. Basil the Great edited this down in his
        liturgy to about two and a half hours, and later (around 400 AD) the
        Liturgy ascribed to St. John Chrysostom further reduced it to about one
        and a half hours. For most of the year, the Divine Liturgy of St. John
        Chrysostom is used, while during Sundays in Lent the longer (and more
        ancient) Liturgy of St. Basil is used. Also during Lent, the Liturgy of
        the Presanctified Gifts, traditionally ascribed to St. Gregory the
        Great, Pope of Rome, is served on the weekdays of Lent when normal
        liturgical services are not served.
      </ParagraphA>
      <ParagraphA>
        When you arrive for Divine Liturgy on Sunday morning, worship will
        already be in progress and you will feel chagrined at arriving late. You
        are not late; the priest, reader and some parishioners are just winding
        up the Third Hour, which began about a quarter of an hour before. Before
        the Third Hour, the priest and deacon had other preparatory services;
        they will be at the altar for a total of over three hours on Sunday
        morning, "standing in the flame," as one Orthodox priest put it.
        Orthodoxy is not for people who find church boring.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>About Mary</StjMainContainerFontPatuaH2>

      <ParagraphA>
        We love her and it shows. What can we say? She’s His Mother. We often
        address her as "Theotokos," which means Birth-giver of God. In providing
        the physical means for God to become man, she made possible our
        salvation. It is important to note that, after the Lord Himself, the
        most important personage in the Orthodox Church is that of a woman - the
        Holy Birth-Giver of God, and Ever-Virgin Mary the Mother of our Lord
        Jesus Christ.
      </ParagraphA>
      <ParagraphA>
        Not that we think she or any other saints have magical powers or are
        demigods. When we sing "Holy Theotokos, save us," we don’t mean "save"
        in an eternal sense, as we would pray to Christ; we mean "protect,
        defend, take care of us here on earth." Just as we ask for each other’s
        prayers, we ask for the prayers of Mary and the other saints as well.
        They’re not dead, after all, just departed to the other side. Icons
        surround us, in part, to remind us that all the saints are joining us
        invisibly in our worship.
      </ParagraphA>
      <ParagraphA>
        Mary is also particulary loved as our own Mother in Christ and as the
        spotless and pure image of the Church as the Bride of God.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        Windows into Heaven
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        Every Orthodox church will have an iconostas before its altar.
        "Iconostas" means "icon-stand." The basic set-up of two large icons
        creates, if you use your imagination, three doors. The central opening,
        in front of the altar itself, usually has two doors, called the “Holy
        Doors,” because that is where the King of Glory comes out to the
        congregation in the Eucharist. Only the bishop, priest and deacon, who
        bear the Eucharist, use these Doors. The Holy Doors are also flanked by
        two icons of Jesus Christ (on the right) and the Ever-Virgin Mary (on
        the left).
      </ParagraphA>
      <ParagraphA>
        The openings on the other sides of the two main icons have doors with
        icons of two particular deacons: Stephan, one of the first 7 deacons and
        the Church's first martyr, and Lawrence, a Roman deacon who was also a
        martyr. Altar boys and others with business behind the altar use these,
        although no one is to go through any of the doors without an appropriate
        reason. On the outer sections of the iconostas are icons of the parish'
        heavenly Patron, St. John the Theologian (on the far right) and the
        greatest (and last) prophet of the Old Testament, St. John the Baptist
        (on the far left).
      </ParagraphA>
      <ParagraphA>
        Altar service - bishop, priests, deacons, altar boys - is restricted to
        males. Females are invited to participate in every other area of church
        life. Their contribution has been honoured equally with that of males
        since the days of the martyrs; you can’t look around an Orthodox church
        without seeing Mary and other holy women. In most Orthodox churches,
        women do everything else men do: lead congregational singing, paint
        icons, teach classes, read the epistle, and serve on the parish council.
      </ParagraphA>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <StjMainContainerFontPatuaH2>
        Everyone is Welcomed
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        There are about 1.5 million Orthodox in North America and over 300
        million in the world, making Orthodoxy the second-largest Christian
        communion.
      </ParagraphA>
      <ParagraphA>
        Doing an online search of orthodox parishes in a typical city, you’ll
        find a multiplicity of churches: Ukrainian, Greek, Romanian, Russian,
        Antiochian, Serbian, and others. All these Orthodox bodies are one
        church; the "ethnic" designation simply refers to the parish's origins
        (similar to "Irish" Catholics or "Swedish" Lutherans).
      </ParagraphA>
      <ParagraphA>
        The astonishing thing about this ethnic multiplicity is its theological
        and moral unity. Orthodox throughout the world hold unanimously to the
        fundamental Christian doctrines taught by the Apostles and handed down
        by their successors, the bishops, throughout the centuries. They also
        hold to the moral standards of the Apostles. One could attribute this
        unity to historical accident. We would attribute it to the Holy Spirit.
      </ParagraphA>
      <ParagraphA>
        Why then the multiplicity of ethnic churches? These national
        designations obviously represent geographic realities. Since North
        America is also a geographic unity, one day we will likewise have a
        unified national church, an American Orthodox Church. This was the
        original plan, but due to a number of complicated historical factors, it
        didn’t happen that way. Instead, each ethnic group of Orthodox
        immigrating to this country developed its own church structure. This
        multiplication of Orthodox jurisdictions is a temporary aberration, and
        much prayer and planning is going into breaking through these
        unnecessary walls.
      </ParagraphA>
      <ParagraphA>
        Orthodoxy seems startlingly different at first, but as the weeks go by
        it gets to be less so. It will begin to feel more and more like home,
        and it will draw you into the Kingdom of God. We hope that your first
        visit to an Orthodox church will be enjoyable, and that it won’t be your
        last.
      </ParagraphA>
      <p className="fst-italic">
        Based on the Ancient Faith Publishing pamphlet "12 Things I Wish I Had
        Known" by Frederica Mathewes-Green. Used by permission of the author.
      </p>
    </StJohnMainContainer1>
  </div>
);
