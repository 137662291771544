import React from "react";
import {
  ParagraphA,
  PageHeadingParallax,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import { StjMainContainerFontPatuaH2 } from "../../layout/headings/headings.jsx";
import { StjYTVideoContainer } from "../../ui/yt_video_container/yt_video_container.jsx";
import { routes } from "../../../../site_links.jsx";

export const VideosPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/jesus_icon_1.jpg"}
      text={"Videos"}
    />

    <StJohnMainContainer1>
      <div className="text-center">
        <StjMainContainerFontPatuaH2>
          The Transfigured Life Podcast
        </StjMainContainerFontPatuaH2>
        <ParagraphA>
          Selected videos from the{" "}
          <a
            href={routes.transfiguredLifePodcast}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <span className="fst-italic">The Transfigured Life Podcast</span>
          </a>{" "}
          with Father Ivanoff and Luther Menard
        </ParagraphA>
      </div>
      <div className="py-5 d-flex justify-content-around flex-wrap">
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=GPqNu60tX30"}
          title={
            "Sola Scriptura or Holy Tradition? w/ Dr. Gavin Ortlund And Fr. Stephen De Young"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=fqbcsaUYbD4"}
          title={"Protestant Pastor discovers the truth of Orthodoxy!"}
        />
      </div>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <div className="text-center">
        <StjMainContainerFontPatuaH2>
          Interviews and Apologetic Debates with Father Jonathan Ivanoff
        </StjMainContainerFontPatuaH2>
      </div>
      <div className="py-5 d-flex justify-content-around flex-wrap">
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=JjkbelOT-mQ"}
          title={
            "Ancient Faith Today - Orthodoxy Gains New Followers in the US (w/ Fr. Jonathan Ivanoff)"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=oCpW0qgGwr4"}
          title={
            "The Great Debate About Mary (Fr. Jonathan Ivanoff - Orthodox vs. Pr. Samuel Farag - Protestant)"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=iUxrob7Wj5M"}
          title={
            "Father Jonathan Ivanoff - Orthodox priest answers common protestant questions | Part 1"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=XsNY2kCbKDY"}
          title={
            "Father Jonathan Ivanoff - Orthodox priest answers common protestant questions | Part 2"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=fALOhoykHx4"}
          title={
            "Father Jonathan Ivanoff - Orthodox priest answers common protestant questions | Part 3"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=_eFAMPMZB4Q"}
          title={
            "UNDERSTANDING Eastern Orthodoxy Q&A x Fr Jonathan Ivanoff x Dr. Bob X Vocab"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=HVV2cVC-wzA"}
          title={"Conversing w RESPECTFUL Eastern Orthodox !"}
        />

        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=eliQSZ-DsTo"}
          title={
            "Tony Nash/Cameron Brinkman Vs Luther Menard/Jonathan Ivanoff- Sola Scriptura or Orthodox Church?"
          }
        />
      </div>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <div className="text-center">
        <StjMainContainerFontPatuaH2>
          Lenten Retreat 2022 - Thinking Orthodox with Dr. Eugenia Constantinou
        </StjMainContainerFontPatuaH2>
        <ParagraphA>
          On April 1st and 2nd of 2022 we were honored to host Orthodox scholar
          and author Dr. Euginia Constantinou who presented on the topic of her
          popular book - "Thinking Orthodox - Acquiring the Orthodox Christian
          Mind." Below is video from the second session of her presentation.
        </ParagraphA>
      </div>
      <div className="py-5 d-flex justify-content-around flex-wrap">
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=Hj7OWIhi1Yw"}
          title={
            "St. John the Theologian Orthodox Church: Thinking Orthodox Session 2"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=DAvvsj_CbD4"}
          title={
            "St. John the Theologian Orthodox Church: Thinking Orthodox Session 2 Reflection Fr. Jonathan Ivanoff"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=dhjERD0Wn3U"}
          title={
            "St. John the Theologian Orthodox Church: Thinking Orthodox Session 3"
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=uk8aYapnfi0"}
          title={
            "St. John the Theologian Orthodox Church Thinking Orthodox Session 3 Reflection Fr. Jonathan Ivanoff"
          }
        />
      </div>
    </StJohnMainContainer1>
  </div>
);
