import {
  returnYearsFromHTMLAsString,
  returnAlbumsForYearSortedFromHTMLAsString,
  returnAlbumImagesUrlsAndDescriptionUrlsFromHTMLAsString,
} from "../components/util.js";

export const fetchYears = () => {
  return $.ajax({
    url: "./assets_2/albums/",
    type: "GET",
  }).then((result) => {
    return returnYearsFromHTMLAsString(result);
  });
};

export const fetchAlbumsDataObjects = (year) => {
  return $.ajax({
    url: `./assets_2/albums/${year}`,
    type: "GET",
  }).then(function (response) {
    let albumsObjectsSortedByAlbumDate =
      returnAlbumsForYearSortedFromHTMLAsString(response, year);
    return albumsObjectsSortedByAlbumDate;
  });
};

export const fetchAlbumImagesDescriptionTitleUrls = (year, albumName) => {
  let path = `./assets_2/albums/${year}/${albumName}`;

  return $.ajax({
    url: path,
    type: "GET",
  }).then((results) => {
    return returnAlbumImagesUrlsAndDescriptionUrlsFromHTMLAsString(
      results,
      path
    );
  });
};

export const retrieveAlbumDescription = (albumDescriptionUrl) => {
  return $.ajax({
    url: albumDescriptionUrl,
    type: "GET",
  });
};

export const fetchAlternativeAlbumName = (albumNameUrl) => {
  return $.ajax({
    url: albumNameUrl,
    type: "GET",
  });
};
