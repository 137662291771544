import React, { useEffect } from "react";
import {
  PageHeadingParallax,
  StJohnMainContainer1,
  ParagraphA,
} from "../../layout/containers/containers.jsx";
import { routes } from "../../../../site_links.jsx";
import "./giving.scss";

export const GivingPage = () => {
  useEffect(() => {
    create_tithely_widget();
  }, []);

  const tithelyButtonFix = () => {
    setTimeout(() => {
      document.querySelector(
        "#tithely-widget-wrapper a"
      ).href = `index.html#${routes.giving}`;
    }, 1000);
  };

  return (
    <div>
      <PageHeadingParallax
        backgroundImage={"./assets_2/images/theotokos_and_jesus_silver_1.jpg"}
        text={"Giving"}
      />
      <StJohnMainContainer1>
        <div className="text-center fst-italic fs-5">
          <ParagraphA>
            If you are interested in donating to St. John the Theologian
            Orthodox Church, or if you are a member who has pledged continuous
            financial support, please feel free to make your contribution by
            clicking the button below.
          </ParagraphA>
          <ParagraphA>
            We use Tithe.ly, a secure church funds collection application. If
            you have any questions or concerns,{" "}
            <a target="_blank" href="https://get.tithe.ly/faqs">
              click here{" "}
            </a>
            to learn more about Tithe.ly.
          </ParagraphA>
          <ParagraphA>Thank you for your support.</ParagraphA>

          <button
            className="tithely-give-btn"
            data-church-id="1637318"
            onClick={() => tithelyButtonFix()}
          >
            Give
          </button>
          <br />
          <br />
          <br />
          {/* <ParagraphA>
            You can also support us when you shop on Amazon by including us as
            the charitable organization of your choice on AmazonSmile.
          </ParagraphA>
          <div className="amazon-smile-inst">
            <p style={{ lineHeight: "35px" }}>
              <span>&#8226;</span> Click the logo below to visit the{" "}
              <a
                target="_blank"
                href="https://smile.amazon.com/ref=smi_se_saas_lsmi_smi"
              >
                smile.amazon.com
              </a>{" "}
              page.
            </p>
            <p style={{ lineHeight: "35px" }}>
              <span>&#8226;</span> On your first visit, search for and select{" "}
              <span style={{ color: "#A61717" }}>
                St. John The Theologian MISSION Church
              </span>{" "}
              before you begin shopping.
            </p>
            <p style={{ lineHeight: "35px" }}>
              <span>&#8226;</span> Always shop on Amazon through{" "}
              <a
                target="_blank"
                href="https://smile.amazon.com/ref=smi_se_saas_lsmi_smi"
              >
                smile.amazon.com
              </a>{" "}
              and as long as we are listed as the charitable organization we
              receive donations from eligible purchases you make.
            </p>
          </div>

          <figure>
            <a
              target="_blank"
              href="https://smile.amazon.com/ref=smi_se_saas_lsmi_smi"
            >
              <img
                src="assets_2/icons/amazon-smile-logo.png"
                alt="amazon smile logo"
                style={{ width: "200px" }}
              ></img>
            </a>
          </figure>
          <p>
            Have questions about how it works?{" "}
            <a
              target="_blank"
              href="https://smile.amazon.com/gp/chpf/about/ref=smi_se_dshb_aas_saas"
            >
              Click here
            </a>
            .
          </p>
          <br />
          <p>
            For how to use AmazonSmile on the Amazon mobile app,{" "}
            <a
              target="_blank"
              href="https://smile.amazon.com/b/ref=br_imp?_encoding=UTF8&node=15576745011&pf_rd_m=ATVPDKIKX0DER&pf_rd_s=smile-my-impact-right-2&pf_rd_r=B2V5H3HGECGMW5KNCE1A&pf_rd_r=B2V5H3HGECGMW5KNCE1A&pf_rd_t=45801&pf_rd_p=37df686d-7454-4511-97bb-8b17c5d793e8&pf_rd_p=37df686d-7454-4511-97bb-8b17c5d793e8&pf_rd_i=dashboard"
            >
              click here
            </a>
            .
          </p> */}
        </div>
      </StJohnMainContainer1>
    </div>
  );
};
