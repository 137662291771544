import React from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export const StjLink = ({
  children,
  className,
  onClick,
  to,
  external = false,
}) => {
  if (to.includes("#")) {
    return (
      <NavHashLink className={className} to={to} onClick={onClick}>
        {children}
      </NavHashLink>
    );
  }

  if (external) {
    return (
      <a href={to} className={className} target="_blank'">
        {children}
      </a>
    );
  }

  return (
    <Link className={className} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

export const routes = {
  home: "/",
  videos: "/videos",
  aboutUs: "/about-us",
  orthodoxy: "/orthodoxy",
  photos: "/photos",
  music: "/music",
  calendar: "/calendar",
  giving: "/giving",
  contact: "/contact",
  ourPatronSaint: "/our-patron-saint",
  aboutUs: "/about-us",
  whatToExpect: "/what-to-expect",
  parishHistory: "/parish-history",
  ourClergy: "/about-us#our-clergy",
  ministries: "/ministries",
  usefulLinks: "/orthodoxy#useful-links",
  transfiguredLifePodcast: "https://www.youtube.com/@TheTransfiguredLife",
};

class HeaderLink {
  constructor(name, link, subLinks = [], external = false) {
    this.name = name;
    this.link = link;
    this.subLinks = subLinks;
    this.external = external;
  }
}

export const headerLinks = [
  new HeaderLink("Home", routes.home),
  new HeaderLink("About us", routes.aboutUs, [
    new HeaderLink("Leadership", routes.ourClergy),
    new HeaderLink("Parish History", routes.parishHistory),
    new HeaderLink("Ministries", routes.ministries),
    new HeaderLink("Our Patron Saint", routes.ourPatronSaint),
  ]),
  new HeaderLink("Orthodoxy", routes.orthodoxy, [
    new HeaderLink("What to Expect", routes.whatToExpect),
    new HeaderLink("Useful Links", routes.usefulLinks),
  ]),
  new HeaderLink("Media", routes.videos, [
    new HeaderLink(
      "The Transfigured Life Podcast",
      routes.transfiguredLifePodcast,
      [],
      true
    ),
    new HeaderLink("Videos", routes.videos),
    new HeaderLink("Music", routes.music),
    new HeaderLink("Parish Life Gallery", routes.photos),
  ]),
  new HeaderLink("Calendar", routes.calendar),
  new HeaderLink("Giving", routes.giving),
  new HeaderLink("Contact", routes.contact),
];
