import React, { useEffect } from "react";
import "../styles/main.scss";
import { useLocation } from "react-router-dom";
import { StJohnBody, StJohnMain } from "./layout/containers/containers.jsx";
import { Header } from "./layout/header/header.jsx";
import { Footer } from "./layout/footer/footer.jsx";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home/home.jsx";
import { VideosPage } from "./pages/videos/videos.jsx";
import { MusicPage } from "./pages/music/music.jsx";
import { AboutUsPage } from "./pages/about_us/about_us.jsx";
import { OrthodoxyPage } from "./pages/orthodoxy/orthodoxy.jsx";
import { PhotosPage } from "./pages/photos/photos.jsx";
import { MinistriesPage } from "./pages/ministries/ministries.jsx";
import { GivingPage } from "./pages/giving/giving.jsx";
import { CalendarPage } from "./pages/calendar/calendar.jsx";
import { ContactPage } from "./pages/contact/contact.jsx";
import { OurPatronSaintPage } from "./pages/our_patron_saint/our_patron_saint.jsx";
import { WhatToExpectPage } from "./pages/what_to_expect/what_to_expect.jsx";
import { HistoryPage } from "./pages/history/history.jsx";
import { routes } from "../../site_links.jsx";

const useScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [pathname, hash]);
};

export const StJohnSite = () => {
  useScrollToTop();

  return (
    <StJohnBody>
      <Header />
      <StJohnMain>
        <Routes>
          <Route exact path={routes.home} element={<HomePage />} />
          <Route exact path={routes.videos} element={<VideosPage />} />
          <Route exact path={routes.music} element={<MusicPage />} />
          <Route exact path={routes.aboutUs} element={<AboutUsPage />} />
          <Route exact path={routes.orthodoxy} element={<OrthodoxyPage />} />
          <Route exact path={routes.photos} element={<PhotosPage />} />
          <Route exact path={routes.ministries} element={<MinistriesPage />} />
          <Route exact path={routes.giving} element={<GivingPage />} />
          <Route exact path={routes.calendar} element={<CalendarPage />} />
          <Route exact path={routes.contact} element={<ContactPage />} />
          <Route
            exact
            path={routes.whatToExpect}
            element={<WhatToExpectPage />}
          />
          <Route
            exact
            path={routes.ourPatronSaint}
            element={<OurPatronSaintPage />}
          />
          <Route exact path={routes.parishHistory} element={<HistoryPage />} />
        </Routes>
      </StJohnMain>
      <Footer />
    </StJohnBody>
  );
};
