import React from "react";

const AlbumThumbnail = ({ url, albumName, openModal, active }) => {
  return (
    <figure
      onClick={() => openModal(!active)}
      style={{ backgroundImage: `url('${url}')` }}
    >
      <div className="gallery-year-displays-overlay">
        <h3>{albumName}</h3>
      </div>
    </figure>
  );
};

export default AlbumThumbnail;
