import React from "react";
import {
  PageHeadingParallax,
  ParagraphA,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import {
  StjMainContainerFontPatuaH2,
  StjMainContainerFontPatuaH4,
} from "../../layout/headings/headings.jsx";
import { VideoContainer } from "../../ui/video_container/video_container.jsx";

export const HistoryPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={
        "./assets_2/albums/1984/Archbishop%20Peter%20Visit%20and%20Mission%20Officially%20Established/1984_September_archbishop_peter_visit_a_0044_a.jpg"
      }
      text={"Parish History"}
    />
    <StJohnMainContainer1>
      <div className="text-center mb-2">
        <StjMainContainerFontPatuaH4>
          Photo Slideshow of Forty Years of Ministry
        </StjMainContainerFontPatuaH4>
      </div>
      <VideoContainer
        videoClassName="w-100"
        src={"./assets_2/videos/40years.mp4"}
        fullScreen={true}
      />
    </StJohnMainContainer1>
    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <History />
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <FormerPastors />
    </StJohnMainContainer1>
  </div>
);

const History = () => (
  <>
    <ParagraphA>
      The Orthodox Church of St. John the Theologian began in 1983 with the
      intent to provide an English-language Orthodox presence in the eastern end
      of Suffolk County, Long Island, an area containing over a half-million
      people.
    </ParagraphA>
    <ParagraphA>
      The establishment of the mission was the dream of Father John and Matushka
      Marie Sochka of St. Andrew Orthodox Church in Dix Hills, who envisioned
      the establishment of such a “daughter parish” back in the ‘70’s. After Fr.
      John’s untimely death in 1980, Matushka Marie and several other member of
      the Dix Hills parish who lived out in the Town of Brookhaven got together
      to organize the “Yaphank mission” and saw the first Divine Liturgy served
      on Sunday, December 11, 1983 at Yaphank Presbyterian Church. Choir
      responses were handled by the seminarians of St. Vladimir’s Seminary,
      Crestwood, New York, and Fr. Tom Hopko, Professor of Dogmatic Theology at
      St. Vladimir's, as guest homilist.
    </ParagraphA>
    <_HistoryRollImageSection>
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7650_web_ready.jpg"}
        alt="Church Sign"
      />
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7651_web_ready.jpg"}
        alt="Father Satvroforus"
      />
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7652_web_ready.jpg"}
        alt="Father and parish"
      />
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7637_web_ready.jpg"}
        alt="Liturgy"
      />
    </_HistoryRollImageSection>
    <ParagraphA>
      During this time, the community sponsored numerous spiritual and social
      events such as the Yale Russian Chorus and summer picnics to bring a
      geographically scattered community together for Orthodox worship and
      fellowship. Fr. Stavros Strikis was assigned as the first priest of the
      new mission and worked hard during the first year to establish as regular
      cycle of worship.
    </ParagraphA>
    <_HistoryRollImageSection>
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7638_web_ready.jpg"}
        alt="Church Sign"
      />
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7643_web_ready.jpg"}
        alt="Father Satvroforus"
      />
      <_HistoryRollImage
        imageSrc={"./assets_2/icons/history_roll/IMG_7645_web_ready.jpg"}
        alt="Father and parish"
      />
    </_HistoryRollImageSection>
    <ParagraphA>
      Following Archbishop Peter’s visit in September of 1984, the mission was
      officially established as the Orthodox Mission Church of St. John the
      Theologian, Yaphank, New York. A strong core of approximately twenty to
      thirty people led by Fr. Stavros proceeded to continue services at Yaphank
      Presbyterian Church. Fr. Stavros remained until 1986 and was succeeded by
      Fr. Raymond Courtney, for two years, Fr. Bogdan Djurdjulov, for one year,
      and Fr. Basil Prisacarou, who served until April of 1993.
    </ParagraphA>
    <ParagraphA>
      Father Jonathan Ivanoff, a 1986 graduate of St. Vladimir's Orthodox
      Theological Seminary, was subsequently assigned to the mission in May of
      1993. Through consistent effort and focused outreach, a team of dedicated
      and talented lay leaders working with Fr. Jonathan in the area of
      education and teen youth work, evangelization and mission, and parish
      organization and administration were able to begin making a difference in
      the physical, spiritual and social life of the parish community. Within
      one year, membership had doubled to thirty-eight adult members. A church
      school was started in the fall of 1993 with six students and finished the
      year with eleven students; during the following church school year,
      enrollment began with fourteen children and ended with seventeen. A youth
      group was started with over a half-dozen teenagers enjoying monthly
      fellowship as well as spiritual and Christian social formation.
    </ParagraphA>
    <ParagraphA>
      <_HistoryRollImageSection>
        <_HistoryRollImage
          imageSrc={"./assets_2/icons/history_roll/IMG_7647_web_ready.jpg"}
          alt="Church Ladies"
        />
        <_HistoryRollImage
          imageSrc={"./assets_2/icons/history_roll/IMG_7641_web_ready.jpg"}
          alt="Choir"
        />
        <_HistoryRollImage
          imageSrc={"./assets_2/icons/history_roll/IMG_7633_web_ready.jpg"}
          alt="Father Jonathan"
        />
      </_HistoryRollImageSection>
      By the beginning of 1995, parish growth necessitated a move out of Yaphank
      Presbyterian, who had very kindly and gracioiusly served as our host since
      the Mission’s first service in December, 1983. A five-month term at the
      Mouse Hall in Miller Place was followed by a year at St. Mark’s Lutheran
      Church in Ridge, who invited the Mission to begin services, at no charge,
      in their facilities until St. John’s was able to find property of its own.
    </ParagraphA>
    <ParagraphA>
      Beginning in 1995, the Mission undertook a concerted effort to finally
      identify and locate suitable property for its own use. By the beginning of
      1996, with adult membership exceeding fifty and the church school program
      enrolling over twenty children, a successful capital campaign raised over
      $60,000 to purchase such a place, and in April of 1996 we moved into our
      present facilities at 158 West End Avenue in Shirley. The mortgage was
      paid off in 2006 and an extensive construction renovation project was
      carried out in 2014 and again in 2021-2022 that will allow us to grow and
      do even more to expand both our faith and our mission in the community.
    </ParagraphA>
  </>
);

const _HistoryRollImageSection = ({ children }) => (
  <section className="d-flex justify-content-center flex-wrap">
    {children}
  </section>
);

const _HistoryRollImage = ({ imageSrc, alt }) => (
  <img
    src={imageSrc}
    className="mx-1 my-2"
    style={{ height: "14rem" }}
    alt={alt}
  />
);

const FormerPastors = () => (
  <>
    <div className="text-center">
      <StjMainContainerFontPatuaH2>Former Pastors</StjMainContainerFontPatuaH2>
    </div>
    <div className="row mt-4">
      <_FormerPastor
        url={
          "./assets_2/images/former_pastors/former_pastors_stavros_web_ready.jpg"
        }
        name={"Fr. Stavros Strikis"}
        servedDates={"1983 - 1986"}
      />
      <_FormerPastor
        url={
          "./assets_2/images/former_pastors/former_pastors_raymond_web_ready.jpg"
        }
        name={"+Fr. Raymond Courtney"}
        servedDates={"1986 - 1987"}
      />
      <_FormerPastor
        url={
          "./assets_2/images/former_pastors/former_pastors_bogdan_web_ready.jpg"
        }
        name={"Fr. Bogdan Djurdjulov"}
        servedDates={"June 1987 - May 1988"}
      />
      <_FormerPastor
        url={
          "./assets_2/images/former_pastors/former_pastors_basil_web_ready.jpg"
        }
        name={"+Fr. Basil Prisacarou"}
        servedDates={"July 1987 - May 1993"}
      />
    </div>
  </>
);

const _FormerPastor = ({ name, url, servedDates }) => (
  <section className="col-12 col-md-3">
    <figure>
      <img src={url} alt={name} className="w-100" />
    </figure>
    <div className="text-center mt-lg-0 mb-4">
      <StjMainContainerFontPatuaH4>{name}</StjMainContainerFontPatuaH4>
      <p>Served {servedDates}</p>
    </div>
  </section>
);
