import React from "react";
import { PageHeadingParallax } from "../../layout/containers/containers.jsx";
import {
  StJohnMainContainer1,
  ParagraphA,
} from "../../layout/containers/containers.jsx";
import {
  StjMainContainerFontPatuaH2,
  StjMainContainerFontPatuaH4,
} from "../../layout/headings/headings.jsx";
import { StjYTVideoContainer } from "../../ui/yt_video_container/yt_video_container.jsx";
import "./orthodoxy.scss";

export const OrthodoxyPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/russian_orthodox_cross.jpeg"}
      text={"Eastern Orthodoxy"}
    />
    <StJohnMainContainer1>
      {/* This video was made private */}
      {/* <StjYTVideoContainer
        src={"https://www.youtube.com/watch?v=95P744siC7U"}
        fullScreen={true}
      /> */}

      <StjMainContainerFontPatuaH2>
        What is Orthodoxy?
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        Orthodox Christians often find it hard to describe the Orthodox Church.
        Often called the "best kept secret in American religion," there are
        perhaps 2 million Orthodox Christians in North America, and 300 million
        around the world. The Orthodox Church is the original Church, founded in
        33 A.D., on the day of Pentecost, and has been bearing witness to the
        Gospel of Jesus Christ ever since.
      </ParagraphA>
      <ParagraphA>
        We believe and stand firm in the unchanging truths as revealed by God in
        the Holy Scriptures. No such thing as compromising the Gospel or
        substituting man-made doctrines for those of the God who has revealed
        himself in Trinity - Father, Son, and Holy Spirit. Ancient worship that
        is relevant to and speaks to modern man and society here and now, with
        no watered-down, majority-vote doctrine, fad-of-the-moment teaching, or
        "make-it-up-as-you-go-along" worship.
      </ParagraphA>
      <ParagraphA>
        Instead, we preach Christ: crucified in the flesh and raised from the
        dead for the remission of sins and eternal and everlasting life; the
        Faith "once and for all time delivered to the Saints" (Jude 3). Ancient
        unchanging truths for a modern world, preached, taught, and lived in all
        the fullness of Christ revealed through time and history in His
        incarnation, death, and resurrection from the dead.
      </ParagraphA>

      <StjMainContainerFontPatuaH2>
        What Happened to the Church the Apostles Left Behind?
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        In the years after Jesus’ Resurrection, apostles and missionaries spread
        the Gospel throughout the known world. Soon, five major locations were
        established as centers for the faith: Jerusalem, Antioch, Rome,
        Alexandria, and Constantinople. In the year 1054, the Roman church broke
        from this united Church, and 500 years later Protestant churches began
        breaking away from Rome. But the original Church has remained united in
        the Apostolic Faith since the 1st century. This is Orthodoxy.
      </ParagraphA>
      <ParagraphA>
        One of the tasks of the early Church was defining and defending orthodox
        theology against the battering waves of heresies, which often appeared
        in disputes over the nature of the Trinity, or how Jesus could be both
        God and man. Church Councils met, searched the Scriptures, and
        formulated the common Faith that has stood the test of time for 2,000
        years. From this time, the Church has been called "Orthodox," which
        means "right belief" or "right praise."
      </ParagraphA>
      <ParagraphA>
        Built on the foundation of Christ and His Apostles, nothing has been
        added to our faith, and nothing can be taken away. It is complete. It is
        of God. It is forever.
      </ParagraphA>

      <StjMainContainerFontPatuaH2>Divine Worship</StjMainContainerFontPatuaH2>
      <ParagraphA>
        Orthodox churches still use forms of worship that were practiced in the
        first centuries. Our worship is based to a great extent on passages from
        Scripture. We sing most of the service, joining our voices in simple
        harmony to ancient melodies.
      </ParagraphA>
      <ParagraphA>
        Our worship is focused on God, not on our own enjoyment, fulfillment, or
        fellowship. We come into the presence of God with awe, aware of our
        fallenness and His great mercy. We seek forgiveness and rejoice in the
        great gift of salvation so freely given. Orthodox worship is filled with
        repentance, gratitude, and unending praise.
      </ParagraphA>
      <ParagraphA>
        We try, as best we can, to make our worship beautiful. The example of
        Scripture shows us that God’s design for tabernacle worship (Ex. 25, 26)
        as well as for heavenly worship (Rev. 4) is that worship which is
        offered with as much beauty as possible.
      </ParagraphA>
      <ParagraphA>
        A common misconception is that awe-filled, beautiful worship must be
        rigid, formal, and cold. Orthodox worship shatters that stereotype. The
        liturgy is not a performance, but an opportunity to come together as a
        family of faith before our beloved Father. True Orthodox worship is
        comfortable, warm, and joyful. It could be nothing less in His heavenly
        presence.
      </ParagraphA>

      <StjMainContainerFontPatuaH2>
        Orthodox Christian Values
      </StjMainContainerFontPatuaH2>
      <ParagraphA>
        Values that are usually termed "Judeo-Christian" have never left
        Orthodoxy. We believe that sexual expression is a treasured gift, one to
        be exercised only within marriage. Marriage is a commitment for life.
        Divorce is a very grave action, and remarriage after divorce is a
        concession to human weakness, undertaken with repentance. Persons with
        homosexual or other extramarital sexual impulses are welcomed as fellow
        servants of God, receiving loving support as they make an offering to
        God of their chastity.
      </ParagraphA>
      <ParagraphA>
        Orthodoxy is a pro-life Church in every way: she has stood against
        abortion since the earliest days of the Church. The Didache (circa A.D.
        110) states, "Do not murder a child by abortion or kill a newborn
        infant." In the midst of a culture which freely practiced abortion,
        infanticide, and the exposure of infants, early Christians were a
        consistent voice against violence, as the Orthodox Church continues to
        be today. In the same spirit, we oppose capital punishment and
        euthanasia, suuporting and upholding the principles of the value and
        worth of God-given life in every way.
      </ParagraphA>
      <ParagraphA>
        Caring for the poor and disadvantaged has always been a concern for the
        Orthodox. The strong sermons of St. John Chrysostom, written in the 4th
        century, bear witness to the importance of this Christian
        responsibility. The Church continues to see its mission in light of the
        whole person, body and soul.
      </ParagraphA>
      <ParagraphA>
        Orthodox believers are right, left, and center on many issues. But where
        Scripture and the witness of the early Church guide us, there is no
        controversy. It’s simple. We uphold and obey God’s will, not our own.
      </ParagraphA>
    </StJohnMainContainer1>
    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <div className="text-center mb-3" id="useful-links">
        <StjMainContainerFontPatuaH4>
          Want more information? Click below.
        </StjMainContainerFontPatuaH4>
      </div>

      <a href="https://journeytoorthodoxy.com" target="_blank">
        <img
          title="Journey to Orthodoxy"
          src="https://journeytoorthodoxy.com/wp-content/uploads/2010/06/adJTO300.png"
          alt="Journey to Orthodoxy"
          className="col-12 offset-md-4 col-md-4"
        />
      </a>
    </StJohnMainContainer1>

    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <div className="text-center">
        <StjMainContainerFontPatuaH2>
          Other Useful Links
        </StjMainContainerFontPatuaH2>
      </div>

      <section>
        <div className="d-flex flex-column align-items-center justify-content-center flex-sm-row flex-wrap">
          <LinkItem
            url={"https://www.oca.org/"}
            imgSrc={"./assets_2/link_logos/oca.png"}
            name={"Orthodox Church in America"}
            description={"The autocephalous church St. John belongs to"}
            alt={"OCA Website Link Logo"}
          />
          <LinkItem
            url={"https://nynjoca.org/"}
            imgSrc={"./assets_2/link_logos/diocese_ny_nj.png"}
            name={"OCA NY/NJ Diocese"}
            description={"The diocese St. John belongs to"}
            alt={"OCA Diocese of NY/NJ Website Link Logo"}
          />
          <LinkItem
            url={"https://orthodoxyinamerica.org/"}
            imgSrc={"./assets_2/link_logos/orthodoxy_in_america_2.jpeg"}
            name={"Orthodoxy in America"}
            description={"Find an Orthodox parish in your area"}
            alt={"Orthodoxy in America Logo"}
          />
          <LinkItem
            url={"https://www.ancientfaith.com/"}
            imgSrc={"./assets_2/link_logos/ancient-faith-logo.jpeg"}
            name={"Ancient Faith Ministries"}
            description={
              "Orthodox multimedia house for the English speaking world"
            }
            alt={"Ancient Faith Ministries Logo"}
            figStyle={{ width: "10rem" }}
          />
          <LinkItem
            url={"https://www.facebook.com/theoriavideos"}
            imgSrc={"./assets_2/link_logos/theoria.jpeg"}
            name={"Theoria"}
            description={
              "Quality videos about Orthodox life, dogma, and spirituality"
            }
            alt={"Theoria Logo"}
            figStyle={{ width: "17rem" }}
          />
          <LinkItem
            url={"https://www.orthodoxintro.org/"}
            imgSrc={"./assets_2/link_logos/orthodox-intro-logo.jpeg"}
            name={"Orthodox Intro"}
            description={
              "Get the most basic information about the Orthodox Christian life"
            }
            alt={"Orthodox Intro Logo"}
            figStyle={{ width: "18rem" }}
          />
          <LinkItem
            url={"https://svspress.com/"}
            imgSrc={"./assets_2/link_logos/svs-logo.png"}
            name={"St. Vladimir's Seminary Press"}
            description={"Orthodox books in the English language"}
            alt={"St. Vladimir's Seminary Press Logo"}
            figStyle={{ width: "5rem" }}
          />
          <LinkItem
            url={"https://www.goarch.org/be-the-bee"}
            imgSrc={"./assets_2/link_logos/be_the_bee.png"}
            name={"Be the Bee"}
            description={
              "Video series about finding God in everything, everyday"
            }
            alt={"Be the Bee Logo"}
            figStyle={{ width: "6rem" }}
          />
          <LinkItem
            url={"https://orthodoxpebbles.com/"}
            imgSrc={"./assets_2/link_logos/pebbles.png"}
            name={"Orthodox Pebbles"}
            alt={"Orthodox Pebbles Logo"}
            description={
              "Resource website for the education of young Orthodox Christians"
            }
            figStyle={{ width: "9rem" }}
          />
          <LinkItem
            url={"https://www.drawneardesigns.com/"}
            imgSrc={"./assets_2/link_logos/draw_near.jpeg"}
            name={"Draw Near Designs"}
            description={"Meaningful resources for Orthodox families"}
            alt={"Draw Near Designs Logo"}
            figStyle={{ width: "15rem" }}
          />
          <LinkItem
            url={"http://mosestheblack.org/"}
            imgSrc={
              "https://i0.wp.com/mosestheblack.org/wp-content/uploads/2020/09/FSMB-Logo-Lettering.jpg"
            }
            name={"Fellowship of St. Moses the Black"}
            description={
              "Linking ancient African Christianity with the African-American experience"
            }
            alt={"Fellowship of St. Moses the Black Logo"}
            figStyle={{ width: "20rem" }}
          />
          <LinkItem
            url={"https://www.orthodoxwest.com/"}
            imgSrc={"./assets_2/link_logos/orthodox_west.png"}
            name={"The Orthodox West"}
            description={
              "Resources and information about the Western Rite of the Orthodox Church"
            }
            alt={"The Orthodox West Logo"}
            figStyle={{ width: "10rem" }}
          />
        </div>
      </section>
    </StJohnMainContainer1>
  </div>
);

const LinkItem = ({ url, imgSrc, name, description, alt, figStyle }) => {
  return (
    <a
      href={url}
      target="_blank"
      className="d-flex flex-column align-items-center mx-3 mt-5 text-decoration-none"
    >
      <figure style={figStyle}>
        <img src={imgSrc} alt={alt} className="w-100" />
      </figure>
      <figcaption className="stj-useful-link-figcaption">
        <span className="fw-bold">{name}</span>
        <br />
        <span style={{ fontSize: ".7rem" }}>{description}</span>
      </figcaption>
    </a>
  );
};
