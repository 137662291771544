import React from "react";

export const StjMainContainerFontPatuaH1 = ({ children }) => (
  <h1 className="fs-1 font-patua">{children}</h1>
);

export const StjMainContainerFontPatuaH2 = ({ children }) => (
  <h2 className="fs-2 font-patua">{children}</h2>
);

export const StjMainContainerFontPatuaH3 = ({ children }) => (
  <h3 className="fs-3 font-patua">{children}</h3>
);

export const StjMainContainerFontPatuaH4 = ({ children }) => (
  <h4 className="fs-4 font-patua">{children}</h4>
);

export const StjMainContainerFontPatuaH5 = ({ children }) => (
  <h5 className="fs-5 font-patua">{children}</h5>
);

export const StjMainContainerFontSourceSansProH1 = ({ children }) => (
  <h1 className="fs-1 font-source-sans-pro fw-bold">{children}</h1>
);

export const StjMainContainerFontSourceSansProH2 = ({ children }) => (
  <h2 className="fs-2 font-source-sans-pro fw-bold">{children}</h2>
);

export const StjMainContainerFontSourceSansProH3 = ({ children }) => (
  <h3 className="fs-3 font-source-sans-pro fw-bold">{children}</h3>
);

export const StjMainContainerFontSourceSansProH4 = ({ children }) => (
  <h4 className="fs-4 font-source-sans-pro fw-bold">{children}</h4>
);

export const StjMainContainerFontSourceSansProH5 = ({ children }) => (
  <h5 className="fs-5 font-source-sans-pro fw-bold">{children}</h5>
);
