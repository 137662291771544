import React from "react";
import {
  PageHeadingParallax,
  StJohnMainContainer1,
  ParagraphA,
} from "../../layout/containers/containers.jsx";
import { Map } from "../../ui/map/map.jsx";

export const ContactPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/seraphim_banner_icon.jpg"}
      text={"Contact"}
    />
    <StJohnMainContainer1>
      <div className="fs-5">
        <ParagraphA>
          <span className="fw-bold">Address: </span>
          <span className="fst-italic">158 West End Ave Shirley, NY 11967</span>
        </ParagraphA>
        <ParagraphA>
          <span className="fw-bold">Telephone Number: </span>
          <span className="fst-italic">
            <a href="tel:6312815960">(631) 281-5960</a>
          </span>
        </ParagraphA>
        <ParagraphA>
          <div className="fw-bold">
            Send Father Jonathan Ivanoff an email at{" "}
            <a href="mailto: stjohnparish@hotmail.com">
              stjohnparish@hotmail.com
            </a>
          </div>
        </ParagraphA>
      </div>
    </StJohnMainContainer1>
    <Map />
  </div>
);
