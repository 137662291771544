function isProduction() {
  if ($(location).attr("hostname").includes("stjohnthetheologian.com")) {
    return true;
  } else {
    return false;
  }
}

export const isVideo = (url) => {
  if (url.includes(".mp4")) {
    return true;
  }

  return false;
};

export const returnYearsFromHTMLAsString = (responseString) => {
  let years = [];

  //TEMP CODE REMOVE AFTER ALL OLD PICS ARE VISISBLE TO PUBLIC
  let showHidden = new URLSearchParams(window.location.hash.split("?")[1]).get(
    "show-hidden"
  );

  let ignoreYears = [
    // "1600",
    // "1984",
    // "1985",
    // "1986",
    // "1987",
    // "1988",
    // "1989",
    // "1990",
    // "1991",
    // "1992",
    // "1993",
    // "1994",
    // "1995",
    // "1996",
    // "1997",
    // "1998",
    // "1999",
    // "2000",
    // "2001",
    // "2002",
    // "2003",
    // "2004",
    // "2005",
    // "2006",
    // "2007",
    // "2017",
    // "2011",
    // "2012",
    // "2015",
  ];
  // TEMP CODE

  let newXML = $(responseString);
  let files = $(newXML)
    .find("a")
    .slice(1)
    .each(function () {
      //skip the first one bc that is just 'parent directory'
      let year = $(this).html().replace(/ /g, ""); //delete white space
      year = year.slice(0, -1); //end at -1 to eliminate the '/'

      if (isNaN(year) === false) {
        //TEMP CODE REMOVE AFTER ALL OLD PICS ARE VISISBLE TO PUBLIC
        if (showHidden) {
          years.push(year);
        }
        if (!showHidden && !ignoreYears.includes(year)) {
          years.push(year);
        }
        //

        // UNCOMMENT BELOW WHEN TEMP ABOVE REMOVED
        //only the actual years
        // years.push(year);
      }
    });

  return years;
};

export const returnAlbumsForYearSortedFromHTMLAsString = (
  responseString,
  year
) => {
  let sortedAlbums = [];

  let newXML = $(responseString);

  if (isProduction()) {
    sortedAlbums = returnAlbumsObjectsSortedByAlbumDate(
      $(newXML).find("a").slice(5),
      year
    );
  } else {
    sortedAlbums = returnAlbumsObjectsSortedByAlbumDate(
      $(newXML).find("a").slice(1),
      year
    );
  }

  return sortedAlbums;
};

export const returnAlbumImagesUrlsAndDescriptionUrlsFromHTMLAsString = (
  responseString,
  urlPath
) => {
  let newXML = $(responseString);
  let xmlElsToLoop = isProduction()
    ? $(newXML).find("a").slice(5)
    : $(newXML).find("a").slice(1);

  let dataUrls = {
    imagesUrls: [],
    albumDescriptionUrl: "",
    alternativeAlbumNameUrl: "",
  };

  xmlElsToLoop.each(function () {
    let item = $(this).html().trim();

    if (item === "details.txt") {
      dataUrls.albumDescriptionUrl = urlPath + $(this).attr("href").trim();
    } else if (item === "title.txt") {
      dataUrls.alternativeAlbumNameUrl = urlPath + $(this).attr("href").trim();
    } else if (item === "hide.txt") {
      null;
    } else {
      dataUrls.imagesUrls.push(urlPath + $(this).attr("href").trim());
    }
  });

  return dataUrls;
};

const ignoreAlbum = async (albumName, albumTitle, year) => {
  let showHidden = new URLSearchParams(window.location.hash.split("?")[1]).get(
    "show-hidden"
  );

  let path = `./assets_2/albums/${year}/${albumTitle}`;

  let albumFiles = await (await fetch(path)).text();

  return (albumName.includes("IGNORE") || albumFiles.includes("hide.txt")) &&
    !showHidden
    ? true
    : false;
};

const returnAlbumsObjectsSortedByAlbumDate = async function (
  jQueryListOfAlbums,
  year
) {
  let albumsAndDates = await albumsObjects(jQueryListOfAlbums, year);

  return albumsAndDates.sort(function (a, b) {
    return a.albumDate - b.albumDate;
  }); //sorts the objects in the array by the date
};

const albumsObjects = async function (jQueryListOfAlbums, year) {
  let _albumObjects = [];

  for (let i = 0; i < jQueryListOfAlbums.length; i++) {
    let albumAndDateText = $(jQueryListOfAlbums[i])
      .attr("href")
      .split("%20")
      .join(" "); //%20 is what the xml returns as empty space

    let albumName =
      returnShortAlbumNameWithoutDateFromAlbumTitle(albumAndDateText);

    let [albumDate, albumDateAsString] =
      formattedDateFromNameAndDate(albumAndDateText);

    let _ignoreAlbum = await ignoreAlbum(albumName, albumAndDateText, year);

    if (!_ignoreAlbum) {
      _albumObjects.push({
        albumTitle: albumAndDateText,
        albumName: albumName,
        albumDateAsString: albumDateAsString,
        albumDate: albumDate,
      });
    }
  }

  return _albumObjects;
};

export const formattedDateFromNameAndDate = (albumAndDateText) => {
  let indexWhereDateStarts = albumAndDateText.indexOf("(");

  if (indexWhereDateStarts == -1) {
    //This is assumes that "(" in an album title is only to signify date. This is crappy and needs to be changed)
    return ["", ""];
  }

  let albumDateAsString = albumAndDateText
    .slice(indexWhereDateStarts + 1, -2)
    .replace(/@/g, "/");

  let albumDate = new Date(albumDateAsString);

  return [albumDate, albumDateAsString];
};

export const returnShortAlbumNameWithoutDateFromAlbumTitle = (albumString) => {
  let indexWhereDateStarts = albumString.indexOf("(");

  if (indexWhereDateStarts === -1) {
    //js returns -1 if the char does not exist
    //assumes "/" has been added as the last character
    return albumString.slice(0, albumString.length - 1);
  }

  return albumString.slice(0, indexWhereDateStarts - 1);
};
