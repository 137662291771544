import React from "react";
import { BlackOverlay } from "../containers/containers.jsx";
import { HeaderLogoWithText } from "../../ui/logo/logo.jsx";
import "./footer.scss";
import { FaYoutube, FaTiktok } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { TiSocialInstagram } from "react-icons/ti";

export const Footer = () => (
  <footer className="stj-parallax-1 font-source-sans-pro py-5">
    <BlackOverlay opacity={0.8} />

    <div className="position-relative d-flex align-items-center justify-content-center h-100 flex-wrap text-center">
      <div className="m-3">
        <div className="stj-footer-logo">
          <HeaderLogoWithText />
        </div>

        <p className="mt-2">
          158 West End Ave
          <br />
          Shirley, NY 11967
          <br />
          <a href="tel:6312815960" className="color-white">
            (631) 281-5960
          </a>
        </p>
      </div>

      <div className="m-3">
        <_SocialMediaIcons />
      </div>

      <div className="m-3">
        <p>
          &copy; {new Date().getFullYear()} St. John the Theologian Orthodox
          Church
        </p>
      </div>

      <div className="m-3">
        <p>
          Rev. Jonathan Ivanoff (Archpriest){" "}
          <a href="mailto: stjohnparish@hotmail.com" className="color-white">
            stjohnparish@hotmail.com
          </a>
        </p>
      </div>
    </div>
    <div className="position-relative d-flex align-items-center justify-content-center fst-italic">
      <p>
        Site designed by{" "}
        <a
          href="http://www.micahbello.com/"
          className="color-white"
          target="_blank"
        >
          Miqueas Bello
        </a>
      </p>
    </div>
  </footer>
);

const _SocialMediaIcons = () => (
  <section className="d-flex align-items-center">
    <div className="mx-1">
      <a
        href="https://www.youtube.com/@St.JohntheTheologianOrthodox"
        target="_blank"
      >
        <FaYoutube style={{ fontSize: "3rem", color: "#ffff" }} />
      </a>
    </div>
    <div className="mx-1">
      <a href="https://www.facebook.com/StJohnLongIsland" target="_blank">
        <FaSquareFacebook style={{ fontSize: "2.3rem", color: "#ffff" }} />
      </a>
    </div>
    <div className="mx-1">
      <a
        href="https://www.instagram.com/stjohnthetheologianorthodox/"
        target="_blank"
      >
        <TiSocialInstagram style={{ fontSize: "2.7rem", color: "#ffff" }} />
      </a>
    </div>
    <div className="mx-1">
      <a href="https://www.tiktok.com/@st.johnthetheologian" target="_blank">
        <FaTiktok style={{ fontSize: "2rem", color: "#ffff" }} />
      </a>
    </div>
  </section>
);
