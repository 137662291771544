import React from "react";
import { LargeCalendar } from "../../ui/large_calendar/large_calendar.jsx";
import { SmallCalendar } from "../../ui/small_calendar/small_calendar.jsx";

export const ResponsiveCalendar = () => (
  <div>
    <div className="d-none d-sm-block">
      <LargeCalendar />
    </div>
    <div className="d-sm-none text-center">
      <SmallCalendar />
    </div>
  </div>
);
