import React from "react";
import "./logo.scss";
import { routes, StjLink } from "../../../../site_links.jsx";

export const HeaderLogoWithText = () => (
  <StjLink to={routes.home} className="text-decoration-none">
    <article className="stj-logo">
      <figure className="stj-logo-fig">
        <img src="assets_2/logos/parish_logo_2024.png" alt="St. John Icon" />
      </figure>
      <div className="stj-logo_text font-patua">
        <p className="stj-logo_text_main">St. John the Theologian</p>
        <p className="stj-logo_text_sub">Orthodox Church</p>
      </div>
    </article>
  </StjLink>
);
