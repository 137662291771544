import React from "react";
import {
  PageHeadingParallax,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import GalleryLanding from "../../image_albums/components/gallery-landing.jsx";

export const PhotosPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/icons_group.jpg"}
      text={"Parish Life Photos and Videos"}
    />
    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <GalleryLanding />
    </StJohnMainContainer1>
  </div>
);
