import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { StJohnSite } from "./components/site.jsx";

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("root");
  ReactDOM.render(
    <HashRouter>
      <StJohnSite />
    </HashRouter>,
    root
  );
});
