import React from "react";
import {
  PageHeadingParallax,
  StJohnMainContainer1,
} from "../../layout/containers/containers.jsx";
import { ResponsiveCalendar } from "../../ui/responsive_calendar/responsive_calendar.jsx";

export const CalendarPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={"./assets_2/images/theotokos_jesus_large_icon.jpeg"}
      text={"Parish Calendar"}
    />
    <StJohnMainContainer1>
      <ResponsiveCalendar />
    </StJohnMainContainer1>
  </div>
);
