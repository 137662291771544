import React from "react";
import "./yt_video_container.scss";

export const StjYTVideoContainer = ({
  src,
  title,
  fullScreen = false,
  description,
}) => {
  const videoId = (src) => {
    let id = new URL(src).searchParams.get("v");
    return id;
  };

  const videoWrapperClasses = () => {
    let classes = "videowrapper";

    if (fullScreen) {
      classes += " video-fullscreen";
    } else {
      classes += " video-halfscreen";
    }

    return classes;
  };

  return (
    <div className={videoWrapperClasses()}>
      <article className="video-iframe-wrapper">
        <iframe
          src={`https://www.youtube.com/embed/${videoId(src)}?rel=0`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </article>
      <section className="p-3">
        <h5 className="text-center fw-bold">{title}</h5>
        <p>{description}</p>
      </section>
    </div>
  );
};
