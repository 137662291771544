import React, { useEffect, useRef } from "react";
import "./containers.scss";

export const StJohnBody = ({ children }) => <div>{children}</div>;

export const StJohnMain = ({ children }) => (
  <main className="stj-main font-source-sans-pro">{children}</main>
);

export const StJohnMainContainer1 = ({
  backgroundColor = null,
  style = null,
  children,
}) => {
  const styleObj = () => {
    return backgroundColor ? { backgroundColor } : { backgroundColor: "#fff" };
  };

  return (
    <div style={styleObj()}>
      <div className="container stj-main-container-1" style={style}>
        {children}
      </div>
    </div>
  );
};

export const StJohnMainContainerFluid = ({
  backgroundColor = null,
  style = null,
  children,
}) => {
  const styleObj = () => {
    return backgroundColor ? { backgroundColor } : { backgroundColor: "#fff" };
  };

  return (
    <div style={styleObj()}>
      <div className="" style={style}>
        {children}
      </div>
    </div>
  );
};

export const StJohnMainContainerWithBackgImage1 = ({
  backgroundImage = null,
  overlayOpacity = null,
  children,
}) => {
  const styleObj = () => {
    return backgroundImage
      ? { backgroundImage: `url('${backgroundImage}')` }
      : null;
  };

  const className = () => {
    let _class = "stj-bckg-image-centered-no-repeat";
    overlayOpacity ? (_class += " position-relative") : null;
    return _class;
  };

  return (
    <div style={styleObj()} className={className()}>
      {overlayOpacity ? <BlackOverlay opacity={overlayOpacity} /> : null}
      <div className="container stj-main-container-1">{children}</div>
    </div>
  );
};

export const StJohnMainContainerNoXPaddingWithBackgImage1 = ({
  backgroundImage = null,
  overlayOpacity = null,
  children,
}) => {
  const styleObj = () => {
    return backgroundImage
      ? { backgroundImage: `url('${backgroundImage}')` }
      : null;
  };

  const className = () => {
    let _class = "stj-bckg-image-centered-no-repeat";
    overlayOpacity ? (_class += " position-relative") : null;
    return _class;
  };

  return (
    <div style={styleObj()} className={className()}>
      {overlayOpacity ? <BlackOverlay opacity={overlayOpacity} /> : null}
      <div className="stj-main-container-1">{children}</div>
    </div>
  );
};

export const BlackOverlay = ({ opacity }) => (
  <div className="stj-black-overlay" style={{ opacity }}></div>
);

export const StjFeatureCircle = ({ backgroundImage, heading }) => (
  <article>
    <p className="text-center mb-2 fs-4 font-patua cursor-pointer">{heading}</p>
    <div
      className="stj-feature-circle cursor-pointer stj-shadow-1"
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
    ></div>
  </article>
);

export const StJohnMainContainerSlowParalaxContainer1 = ({
  backgroundImage,
  children,
}) => {
  const parallaxImagecontainer = useRef(null);
  const container = useRef(null);

  const parallaxScroll = () => {
    let containerTop = container.current.getBoundingClientRect().top;
    let windowHeight = window.innerHeight;

    if (containerTop < windowHeight) {
      let value = containerTop;
      parallaxImagecontainer.current.style.top = value * -0.5 + "px";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", parallaxScroll);

    return () => window.removeEventListener("scroll", parallaxScroll);
  }, []);

  return (
    <section
      ref={container}
      className="stj-page-heading-parallax"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <BlackOverlay opacity={0.5} />
      <div
        ref={parallaxImagecontainer}
        className="d-none d-lg-block stj-page-heading-parallax__image-container"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
          transform: "scale(1.2)",
        }}
      ></div>
      <div
        className="d-lg-none stj-page-heading-parallax__image-container"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      ></div>

      <div className="stj-page-heading-parallax__content">{children}</div>
    </section>
  );
};

export const PageHeadingParallax = ({ backgroundImage, text }) => {
  const container = useRef(null);

  const parallaxScroll = () => {
    let value = window.scrollY;

    container.current.style.top = value * 0.5 + "px";
  };

  useEffect(() => {
    window.addEventListener("scroll", parallaxScroll);

    return () => window.removeEventListener("scroll", parallaxScroll);
  }, []);

  return (
    <section className="stj-page-heading-parallax">
      <BlackOverlay opacity={0.5} />
      <div
        ref={container}
        className="d-none d-lg-block stj-page-heading-parallax__image-container"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      ></div>
      <div
        className="d-lg-none stj-page-heading-parallax__image-container"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      ></div>

      <div className="stj-page-heading-parallax__content">
        <div className="d-flex justify-content-center align-items-center h-100">
          <h1 className="page-heading-parallax__heading">{text}</h1>
        </div>
      </div>
    </section>
  );
};

export const ParagraphA = ({ children }) => <p className="py-2">{children}</p>;

export const StjDisplayOnScrollContainer = ({ children }) => {
  const container = useRef(null);

  const displayWhenInView = () => {
    let containerTop = container.current.getBoundingClientRect().top;
    let displayDelayBounds = 150;
    let windowHeight = window.innerHeight;

    if (containerTop + displayDelayBounds < windowHeight) {
      container.current.classList.add("stj-delayed-scroll-active");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", displayWhenInView);

    return () => window.removeEventListener("scroll", displayWhenInView);
  }, []);

  return (
    <>
      <div
        className="d-none d-lg-block stj-delayed-scroll-container"
        ref={container}
      >
        {children}
      </div>
      <div className="d-lg-none">{children}</div>
    </>
  );
};

export const GeneralTextAndImageOnSidePanel1 = ({
  children,
  imgSrc = null,
  imageSideOnLeft = true,
}) => {
  let rowCss = "my-5 row ";

  if (!imageSideOnLeft) {
    rowCss += "flex-row-reverse";
  }

  return (
    <article className={rowCss}>
      <section className={imgSrc ? "col-lg-5" : "d-none"}>
        <StjDisplayOnScrollContainer>
          <figure>
            <img src={imgSrc} className="w-100 stj-shadow-1" alt="" />
          </figure>
        </StjDisplayOnScrollContainer>
      </section>
      <section className={imgSrc ? "mt-3 mt-lg-0 col-lg-7" : "col-12"}>
        {children}
      </section>
    </article>
  );
};
