// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets_2/images/candles_and_cross.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer{color:#fff;min-height:14rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:relative}.stj-footer-logo{font-size:.5rem}", "",{"version":3,"sources":["webpack://./src_2/components/layout/footer/footer.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,gBAAA,CACA,wDAAA,CACA,iBAAA,CAGF,iBACE,eAAA","sourcesContent":["footer {\n  color: #fff;\n\n  min-height: 14rem;\n  background-image: url(\"/assets_2/images/candles_and_cross.jpg\");\n  position: relative;\n}\n\n.stj-footer-logo {\n  font-size: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
