import React from "react";
import { StjMainContainerFontPatuaH2 } from "../../../layout/headings/headings.jsx";
import { Carousel, CarouselItem } from "../../../ui/carousel/carousel.jsx";
import { StJohnMainContainerWithBackgImage1 } from "../../../layout/containers/containers.jsx";
import "./parish_bulletin.scss";
import { routes } from "../../../../../site_links.jsx";

export const ParishBulletinSection = () => (
  <Carousel>
    <CarouselItem>
      <a
        href={routes.transfiguredLifePodcast}
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <StJohnMainContainerWithBackgImage1
          backgroundImage={"./assets_2/images/transfiguration_pc_blur.png"}
          overlayOpacity={0.7}
        >
          <div className="position-relative row d-flex align-items-lg-center st-bulletin-container">
            <section className="col-lg-4 d-flex align-items-center">
              <div className="text-center">
                <section style={{ color: "#fff" }}>
                  <h2 className="alexandria fs-3">
                    Check out the popular Orthodox YouTube channel 'The
                    Transfigured Life' with Fr. Ivanoff and Luther Menard
                  </h2>
                </section>
              </div>
            </section>

            <section className="col-lg-8">
              <figure>
                <img
                  src="./assets_2/images/transfigured_life.png"
                  alt="Transfigured Life Logo"
                  className="w-100 stj-shadow-1"
                />
              </figure>
            </section>
          </div>
        </StJohnMainContainerWithBackgImage1>
      </a>
    </CarouselItem>
  </Carousel>
);
