import React, { useEffect, useRef } from "react";
import { StjLink } from "../../../../../site_links.jsx";
import { HeaderLogoWithText } from "../../../ui/logo/logo.jsx";
import "./header_desktop.scss";

export const HeaderDesktop = ({ links }) => {
  const header = useRef(null);

  const headerOnScroll = () => {
    let pagePosition = window.scrollY;

    if (pagePosition > 350) {
      header.current.classList.remove("d-none");
      header.current.classList.add(
        "stj-header-dt--active-scrolling-entrance-animation"
      );
    } else {
      if (
        header.current.classList.contains(
          "stj-header-dt--active-scrolling-entrance-animation"
        )
      ) {
        header.current.classList.remove(
          "stj-header-dt--active-scrolling-entrance-animation"
        );
        header.current.classList.add(
          "stj-header-dt--active-scrolling-exit-animation"
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", headerOnScroll);

    return () => window.removeEventListener("scroll", headerOnScroll);
  }, []);

  return (
    <>
      <_Header links={links} />
      <_Header links={links} scrollHeaderRef={header} />
    </>
  );
};

const _Header = ({ links, scrollHeaderRef = null }) => (
  <header
    className={
      scrollHeaderRef
        ? "stj-header-dt stj-header-dt--active-scrolling d-none"
        : "stj-header-dt"
    }
    ref={scrollHeaderRef}
  >
    <section className="stj-header-logo">
      <HeaderLogoWithText />
    </section>

    <section className="w-100 h-100">
      <nav className="font-source-sans-pro h-100">
        <ul className="stj-header-dt-nav-items">
          {links.map((l, idx) => {
            return (
              <li key={idx} className="stj-header-dt-nav-items__li">
                <StjLink
                  to={l.link}
                  external={l.external}
                  className="stj-header-dt-nav-items__link"
                >
                  {l.name}
                </StjLink>
                {l.subLinks.length > 0 ? (
                  <ul className="stj-header-dt-nav-items__sub-links">
                    {l.subLinks.map((l, idx) => {
                      return (
                        <li key={idx} className="my-2">
                          <StjLink
                            to={l.link}
                            className="stj-header-dt-nav-items__sub-link"
                            external={l.external}
                          >
                            {l.name}
                          </StjLink>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  </header>
);
