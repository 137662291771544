import React, { useState } from "react";
import { StjLink } from "../../../../../site_links.jsx";
import { HeaderLogoWithText } from "../../../ui/logo/logo.jsx";
import "./header_mobile.scss";

export const HeaderMobile = ({ links }) => {
  const [navOpen, setNavOpen] = useState(false);

  const handleBurgerClick = () => {
    if (navOpen) {
      document
        .getElementById("mobile-nav")
        .classList.toggle("stj-header-mobile-slide-out");

      //NOTE
      //below commented bc sometimes the animation was yanky
      //classes .stj-header-mobile-slide-in and .stj-header-mobile-slide-out
      //also set to animation none. Uncomment to see animation

      // setTimeout(() => {
      //   setNavOpen(false);
      //   setBodyScroll();
      // }, 500);

      //comment out below for animation. See above
      setNavOpen(false);
      setBodyScroll();

      return;
    }

    if (!navOpen) {
      setNavOpen(true);
      setNoBodyScroll();
    }
  };

  const setNoBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  };

  const setBodyScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = "";
  };

  const subMenu = (menu) => {
    document.getElementById(`sub-menu-${menu}`).classList.toggle("d-none");
  };

  return (
    <header className="stj-header-mobile">
      <section className="stj-header-logo">
        <HeaderLogoWithText />
      </section>

      <section>
        <figure
          className="stj-header-mobile-burger-fig"
          onClick={handleBurgerClick}
        >
          ☰
        </figure>
      </section>
      {navOpen ? (
        <nav
          className="stj-header-mobile-nav stj-header-mobile-slide-in font-source-sans-pro"
          id="mobile-nav"
        >
          <figure
            className="stj-header-mobile-close"
            onClick={handleBurgerClick}
          >
            ˟
          </figure>
          <ul className="stj-header-mobile-nav-items">
            {links.map((l, idx) => {
              return (
                <li className="stj-header-mobile-nav-items__li" key={idx}>
                  <div className="d-flex justify-content-center align-items-center position-relative">
                    <StjLink
                      className="stj-header-mobile-nav-items__link"
                      to={l.link}
                      onClick={handleBurgerClick}
                      external={l.external}
                    >
                      {l.name}
                    </StjLink>
                    {l.subLinks.length > 0 ? (
                      <figure
                        className="stj-header-mobile-nav-items__link-figure"
                        onClick={() => subMenu(l.name)}
                      >
                        +
                      </figure>
                    ) : null}
                  </div>
                  {l.subLinks.length > 0 ? (
                    <ul
                      className="stj-header-mobile-nav-items__sub-links d-none"
                      id={`sub-menu-${l.name}`}
                    >
                      {l.subLinks.map((l, idx) => {
                        return (
                          <li key={idx} className="mt-2">
                            <StjLink
                              className="stj-header-mobile-nav-items__sub-link"
                              to={l.link}
                              onClick={handleBurgerClick}
                              external={l.external}
                            >
                              {l.name}
                            </StjLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </nav>
      ) : null}
    </header>
  );
};
