import React, { useState, useEffect } from "react";
import { isVideo } from "../util";
import { VideoContainer } from "../../../ui/video_container/video_container.jsx";
import { checkIfInMobileView } from "../../../utils";

const pauseAllVideosOnPage = () => {
  let videos = document.getElementsByTagName("video");

  for (let i = 0; i < videos.length; i++) {
    videos[i].pause();
  }
};

const AlbumModal = ({
  year,
  albumName,
  albumDate,
  imagesUrls,
  closeModal,
  active,
  albumDescription,
}) => {
  useEffect(() => {
    $("body").css({ overflow: "hidden" });
    setTimeout(() => {
      displayCorrectScrollArrows();
    }, 3000);

    return () => {
      $("body").css({ overflow: "" });
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      pauseAllVideosOnPage();

      if (checkIfInMobileView()) {
        setInMobileView(true);
      } else {
        setInMobileView(false);
      }
    });

    return () => window.removeEventListener("scroll", headerOnScroll);
  }, []);

  const [inMobileView, setInMobileView] = useState(checkIfInMobileView());
  const [selectedImgUrl, setSelectedImgUrl] = useState(null);

  function handleThumbnailClick(url) {
    pauseAllVideosOnPage();
    setSelectedImgUrl(url);
  }

  function displayImageThumbnails() {
    return imagesUrls.map((url) => {
      let imgClasses = "image-thumbnail";
      selectedImgUrl === url
        ? (imgClasses = imgClasses + " enlarged-thumbnail")
        : null;

      if (isVideo(url)) {
        return (
          <VideoContainer
            key={url}
            src={url}
            controls={inMobileView && selectedImgUrl == url}
            displayPlayIcon={selectedImgUrl != url}
            onClick={() => handleThumbnailClick(url)}
            className={imgClasses}
          ></VideoContainer>
        );
      } else {
        return (
          <img
            className={imgClasses}
            src={url}
            key={url}
            onClick={() => handleThumbnailClick(url)}
          ></img>
        );
      }
    });
  }

  function returnSelectedImageUrl() {
    return selectedImgUrl ? selectedImgUrl : imagesUrls[0];
  }

  function handleArrowClick() {
    let pixelsToScroll =
      event.target.id === "thumbnail-right-arrow" ? 100 : -100;

    let thumbnailsContainer = $("#image-thumbnails-container");
    let currentScrollLocation = thumbnailsContainer.scrollLeft();
    thumbnailsContainer.scrollLeft(currentScrollLocation + pixelsToScroll);

    displayCorrectScrollArrows();
  }

  function displayCorrectScrollArrows() {
    if (isThumnbnailsScrollAtStart() === true) {
      $("#thumbnail-right-arrow").css("color", "transparent");
      $("#thumbnail-right-arrow").css("cursor", "default");
    } else {
      $("#thumbnail-right-arrow").css("color", "");
      $("#thumbnail-right-arrow").css("cursor", "");
    }

    if (isThumnbnailsScrollAtEnd() === true) {
      $("#thumbnail-left-arrow").css("color", "transparent");
      $("#thumbnail-left-arrow").css("cursor", "default");
    } else {
      $("#thumbnail-left-arrow").css("color", "");
      $("#thumbnail-left-arrow").css("cursor", "");
    }
  }

  function isThumnbnailsScrollAtStart() {
    let thumbnailsContainer = $("#image-thumbnails-container");
    if (
      thumbnailsContainer[0].scrollWidth - thumbnailsContainer.scrollLeft() <
      thumbnailsContainer.outerWidth()
    ) {
      return true;
    }

    return false;
  }

  function isThumnbnailsScrollAtEnd() {
    let thumbnailsContainer = $("#image-thumbnails-container");
    if (thumbnailsContainer.scrollLeft() === 0) {
      return true;
    }

    return false;
  }

  const handleModalClick = (event) => {
    if (
      event.target.tagName !== "IMG" &&
      event.target.tagName != "VIDEO" &&
      !event.target.className.includes("thumbnail-arrows")
    ) {
      closeModal(!active);
    }
  };

  const imageAndVideoCount = () => {
    let images = 0;
    let videos = 0;

    imagesUrls.forEach((url) => {
      if (url.includes(".mp4")) {
        videos += 1;
      } else {
        images += 1;
      }
    });

    let text = "";

    if (images > 1) {
      text += `(${images} images`;
    } else if (images == 1) {
      text += "(1 image";
    }

    if (videos > 1 && images > 1) {
      text += `, ${videos} videos`;
    } else if (videos == 1 && images > 1) {
      text += ", 1 video";
    } else if (videos > 0 && images == 0) {
      text += `(${videos} videos`;
    } else if (videos == 0 && images == 0) {
      text += "1 video";
    }

    text += ")";

    return text;
  };

  return (
    <div
      id="photo-album-modal"
      className="stjohn-modal"
      onClick={(e) => handleModalClick(e)}
    >
      <header className="gallery-modal-header mb-3">
        <div className="d-flex justify-content-end">
          <div
            className="album-modal-close"
            onClick={() => closeModal(!active)}
          >
            &times;
          </div>
        </div>
        <p id="album-modal-header-text">
          <span id="album-modal-title">{albumName}</span>
        </p>
        <p className="color-white">
          <span id="album-modal-date"> {albumDate}</span>
          <span id="album-modal-num-imgs"> {imageAndVideoCount()}</span>
        </p>
        <p id="album-modal-description">{albumDescription}</p>
      </header>

      <div>
        <section
          className="image-selected-display-container"
          id="image-selected-display-container"
        >
          {isVideo(returnSelectedImageUrl()) ? (
            <VideoContainer
              src={returnSelectedImageUrl()}
              key={returnSelectedImageUrl()}
            />
          ) : (
            <img src={returnSelectedImageUrl()}></img>
          )}
        </section>

        <section className="thumbnails-section">
          <a
            className="thumbnail-arrows prevent-highlight"
            id="thumbnail-left-arrow"
            style={{ color: "transparent" }}
            onClick={() => handleArrowClick()}
          >
            &#10094;
          </a>
          <div
            className="image-thumbnails-container"
            id="image-thumbnails-container"
            onScroll={() => displayCorrectScrollArrows()}
          >
            {displayImageThumbnails()}
          </div>
          <a
            className="thumbnail-arrows prevent-highlight"
            id="thumbnail-right-arrow"
            style={{ color: "transparent" }}
            onClick={() => handleArrowClick()}
          >
            &#10095;
          </a>
        </section>
      </div>
    </div>
  );
};

export default AlbumModal;
