import React from "react";

export const Map = () => (
  <iframe
    width="100%"
    height="300"
    frameBorder="0"
    style={{ border: "0" }}
    src="https://www.google.com/maps/embed/v1/place?q=158+West+End+Ave,+Shirley,+NY+11967&key=AIzaSyATXtj6rSBPJQK6KkoonALAPA-eCkHgyoM"
    allowFullScreen
  ></iframe>
);
