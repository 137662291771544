import React from "react";

export const LargeCalendar = () => (
  <section className="flex-horizontal-center">
    <iframe
      src="https://calendar.google.com/calendar/b/2/embed?height=400&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=c3Rqb2hudGhlb2xvZ2lhbmNodXJjaEBnbWFpbC5jb20&amp;src=Z2dvaTBxczlka3VubzNlMHZkdjRyZjk4MTdlZ3UwN3NAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%2322AA99&amp;color=%2381910B&amp;&amp;hl=en&amp;title=St.%20John%20the%20Theologian%20Orthodox%20Church"
      style={{ border: "0" }}
      width="100%"
      height="700"
      frameBorder="0"
      scrolling="no"
    ></iframe>
  </section>
);
