import React, { useState, useEffect } from "react";
import AlbumThumbnail from "./album-thumbnail.jsx";
import AlbumModal from "./album-modal.jsx";
import {
  fetchAlbumImagesDescriptionTitleUrls,
  retrieveAlbumDescription,
  fetchAlternativeAlbumName,
} from "../../ajax-calls/image-gallery-ajax-calls.js";
import { isVideo } from "../util.js";

const YearAlbum = ({
  albumName,
  year,
  albumDate,
  albumTitle,
  display = false,
}) => {
  const [imagesUrls, setImagesUrls] = useState(null);
  const [albumDescription, setAlbumDescription] = useState(null);
  const [active, setActive] = useState(display);
  const [alternativeAlbumName, setAlternativeAlbumName] = useState(null);

  useEffect(() => {
    handleAlbumDataFetching();
  }, []);

  function handleAlbumDataFetching() {
    fetchAlbumImagesDescriptionTitleUrls(year, albumTitle)
      .then((albumData) => {
        setImagesUrls(albumData.imagesUrls);
        if (albumData.albumDescriptionUrl) {
          retrieveAlbumDescription(albumData.albumDescriptionUrl).then(
            (albumDescription) => {
              setAlbumDescription(albumDescription);
            }
          );
        }

        if (albumData.alternativeAlbumNameUrl) {
          fetchAlternativeAlbumName(albumData.alternativeAlbumNameUrl).then(
            (alternativeAlbumName) => {
              setAlternativeAlbumName(alternativeAlbumName);
            }
          );
        }
      })
      .catch((error) => {
        console.log(
          "there was error retrieving image urls and description for " +
            albumName
        );
      });
  }

  function thumbnailImage() {
    let url = "";

    for (let i = 0; i < imagesUrls.length; i++) {
      let _url = imagesUrls[i];

      if (!isVideo(_url)) {
        url = _url;
        break;
      }
    }

    //if there are no images and just videos, use placement image
    if (url == "") {
      url = "./assets_2/images/russian_orthodox_cross.jpeg";
    }

    return url;
  }

  if (imagesUrls == null && albumDescription == null) {
    return null;
  } else if (active == false) {
    return (
      <AlbumThumbnail
        url={thumbnailImage()}
        albumName={alternativeAlbumName || albumName}
        albumDate={albumDate}
        openModal={setActive}
        active={active}
      />
    );
  } else if (active) {
    return (
      <AlbumModal
        year={year}
        albumName={alternativeAlbumName || albumName}
        imagesUrls={imagesUrls}
        albumDate={albumDate}
        closeModal={setActive}
        active={active}
        albumDescription={albumDescription}
      />
    );
  }
};

export default YearAlbum;
