import React, { useEffect, useState } from "react";
import AlbumThumbnailIndex, {
  ThumbnailsContainer,
} from "./year-albums/album-thumbnail-index.jsx";
import { fetchYears } from "../ajax-calls/image-gallery-ajax-calls.js";
import { useLocation } from "react-router-dom";
import "../albums.scss";
import {
  returnShortAlbumNameWithoutDateFromAlbumTitle,
  formattedDateFromNameAndDate,
} from "./util.js";
import YearAlbum from "./year-albums/year-album.jsx";

const GalleryLanding = () => {
  const [years, setYears] = useState([]);
  const [yearSelected, setYearSelected] = useState(null);
  const [singleAlbumDisplay, setSingleAlbumDisplay] = useState(null);

  const location = useLocation();

  useEffect(() => {
    let albumTitle = new URLSearchParams(location.search).get("album");

    if (albumTitle) {
      let albumName = returnShortAlbumNameWithoutDateFromAlbumTitle(albumTitle);
      let [_, albumDateAsString] = formattedDateFromNameAndDate(albumTitle);
      let year = new URLSearchParams(location.search).get("year");

      if (albumTitle && albumName && albumDateAsString && year) {
        let singleAlbum = {
          albumTitle: albumTitle,
          albumName: albumName,
          albumDate: albumDateAsString,
          year: year,
        };
        setSingleAlbumDisplay(singleAlbum);
      }
    }
  }, []);

  function setYearsInState() {
    fetchYears()
      .then((years) => {
        setYears(years);
        setYearSelected(years[years.length - 1]);
      })
      .catch((error) => console.log(error.message));
  }

  function displayYearLinks() {
    if (years.length == 0) {
      setYearsInState();
      return "";
    } else {
      return years.map((year, idx) => {
        let className =
          yearSelected === year
            ? "photo-gallery-year-txt chosen-year"
            : "photo-gallery-year-txt";

        return (
          <span
            className={className}
            id={year}
            key={idx}
            onClick={(e) => selectYearLink(e)}
          >
            {year}
          </span>
        );
      });
    }
  }

  function selectYearLink(e) {
    setYearSelected(e.target.id);
  }

  return (
    <div>
      <div id="main-content">
        <div className="photo-index-container">
          <div className="gallery-index">
            <h1>Photo and Video Gallery</h1>
            {!singleAlbumDisplay ? (
              <div className="photo-year-links">{displayYearLinks()}</div>
            ) : null}
          </div>

          {yearSelected && !singleAlbumDisplay ? (
            <AlbumThumbnailIndex year={yearSelected} />
          ) : null}
          {singleAlbumDisplay ? (
            <ThumbnailsContainer>
              <YearAlbum
                albumName={singleAlbumDisplay.albumName}
                year={singleAlbumDisplay.year}
                albumDate={singleAlbumDisplay.albumDate}
                albumTitle={singleAlbumDisplay.albumTitle}
                display={true}
              />
            </ThumbnailsContainer>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GalleryLanding;
