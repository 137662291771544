import React, { useState } from "react";
import "./video_container.scss";
import { checkIfInMobileView } from "../../utils";

export const VideoContainer = ({
  controls = true,
  onClick = () => null,
  className = "",
  videoClassName = "",
  key,
  src,
  displayPlayIcon = true,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const videoInLineStyle = () => {
    if (checkIfInMobileView()) {
      return { height: "100%", width: "100%" };
    } else {
      return { height: "100%" };
    }
  };

  return (
    <article className={className + " stj-video-wrapper"} onClick={onClick}>
      {!isPlaying && displayPlayIcon ? (
        <p className="stj-video-play-button">▶</p>
      ) : null}

      <video
        key={key}
        controls={controls}
        className={videoClassName}
        style={videoInLineStyle()}
        onPlay={() => handlePlay()}
        onPause={() => handlePause()}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </article>
  );
};
