import React from "react";
import {
  StJohnMainContainer1,
  PageHeadingParallax,
} from "../../layout/containers/containers.jsx";
import { StjMainContainerFontPatuaH2 } from "../../layout/headings/headings.jsx";
import { StjYTVideoContainer } from "../../ui/yt_video_container/yt_video_container.jsx";

export const MusicPage = () => (
  <div>
    <PageHeadingParallax
      backgroundImage={
        "./assets_2/albums/2022/Archbishop%20Michael%20Visit%20(12@11@22)/IMG_7975.jpg"
      }
      text={"Music"}
    />
    <StJohnMainContainer1>
      <a
        href="https://sites.google.com/view/orthodoxchristianmusicproject/home"
        target={"_blank"}
      >
        <figure>
          <img
            className="w-100"
            src="assets_2/images/ocmp.gif"
            alt="The Orthodox Christian Music Project Link"
          />
        </figure>
      </a>
    </StJohnMainContainer1>
    <StJohnMainContainer1 style={{ paddingTop: 0 }}>
      <div className="text-center">
        <StjMainContainerFontPatuaH2>
          Compositions by Choir Director: Rdr. Robert Sirico
        </StjMainContainerFontPatuaH2>
      </div>
      <div className="py-5 d-flex justify-content-around flex-wrap">
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=SFlPcWs-5CE"}
          title={"Paraklesis Ode 9"}
          description={
            "From the complete original setting of the Paraklesis Service of intercession to the Theotokos premiered at Holy Heirarch's Chapel at St. Vladimir's Seminary. This selection was also rerecorded and released as part of the St. Vladimir's Seminary Chorale CD 'Orient'."
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=qLBvFO61hdU"}
          title={"Theotokian: It is Truly Meet"}
          description={
            "Performed at St. John the Theologian Christmas Concert as part of the premiere of the Incarnation Cantata based on the Royal Hours of Christmas written by Rdr. Robert Sirico, and appears on the CD of the cantata."
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=Guqo92yEpfo"}
          title={"Come Let Us All Sing"}
          description={
            "Performed at the Cathedral of the Holy Protection (NYC) during the premiere of Rdr. Robert Sirico's second cantata; The Crucifixion Cantata based on the Royal Hours of Holy Friday, and appears as the finale on the CD of the performance."
          }
        />
        <StjYTVideoContainer
          src={"https://www.youtube.com/watch?v=B-jjJ2-DjYY"}
          title={"Te Deum"}
          description={
            "This Choral-Symphonic setting of the ancient Orthodox prayer in Latin, Te Deum Laudamus, represents a pinnacle of compositional skill, as well the achievement of a life long dream to set this magnificent text in the Romantic period orchestral style.  This is a mock-up rendering intended to promote collaboration between the composer and prospective ensembles."
          }
        />
      </div>
    </StJohnMainContainer1>
  </div>
);
