import React from "react";

export const SmallCalendar = () => (
  <iframe
    src="https://calendar.google.com/calendar/b/2/embed?height=400&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=c3Rqb2hudGhlb2xvZ2lhbmNodXJjaEBnbWFpbC5jb20&amp;src=Z2dvaTBxczlka3VubzNlMHZkdjRyZjk4MTdlZ3UwN3NAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%2322AA99&amp;color=%2381910B&amp;mode=AGENDA&amp;hl=en&amp;title=St.%20John%20the%20Theologian%20Orthodox%20Church"
    style={{
      border: "solid 1px #777",
      width: "100%",
      height: "28rem",
      frameBorder: "0",
      scrolling: "no",
    }}
  ></iframe>
);
