import React from "react";
import { HeaderDesktop } from "./header_desktop/header_desktop.jsx";
import { HeaderMobile } from "./header_mobile/header_mobile.jsx";
import { headerLinks } from "../../../../site_links.jsx";

export const Header = () => {
  return (
    <>
      <div className="d-none d-lg-block">
        <HeaderDesktop links={headerLinks} />
      </div>
      <div className="d-lg-none">
        <HeaderMobile links={headerLinks} />
      </div>
    </>
  );
};
